import request from '@/api/index.js';
export const addrootclassifyOne = (data) => {
    return request.post('/classify/addrootclassify', data)
}
export const getallclassifylist = () => {
    return request.get('/classify/getallclassifylist')
}
export const getallclassifylistOne = () => {
    return request.get('/classify/getallclassifylist')
}
export const addnomralclassify = (data) => {
    return request.post('/classify/addnomralclassify', data)
}
export const modifyClassify=(data)=>{
    return request.post('/classify/modifyClassify',data)
}
export const deleteClassify=(id)=>{
    return request.get(`/classify/deleteClassify?id=${id}`)
}
export const getAllClassifyListForSelect=()=>{
    return request.get(`/classify/getallclassifylistforselect`)
}
export const getPartClassifyList=()=>{
    return request.get('/classify/getPartClassifyList')
}