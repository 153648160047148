<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default{
  data(){
    return{
    }
  }
}
</script>
<style lang="scss">
* {
  margin: 0px;
  padding: 0px;
  font-family: newFamily;
}
</style>