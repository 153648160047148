<template>
  <div class="container">
    <div style="display: inline-block;">
      <button @click="back()">＜</button>
    </div>
    <span v-for="item in right_limit" :class="currentPageNumber==item?'active_item':'item'" v-if="left_limit<=item" @click="changePage(item)" :key="item">{{item}}</span>
    <div style="display: inline-block;margin-left:1.25rem">
      <button @click="go()">＞</button>
    </div>
  </div>
</template>
<script>
import { getArticalListInfo } from "@/api/manage/artical";
export default {
  props: ["totel", "currentPage"],
  data() {
    return {
      left_limit: "",
      right_limit: "",
      total:'',
      currentPageNumber:''
    };
  },
  mounted() {
    this.currentPageNumber=this.currentPage
    this.initPage();
  },
  methods: {
    initPage() {
      if (this.currentPageNumber - 2 <= 1) {
        this.left_limit = 1;
      } else {
        this.left_limit = this.currentPageNumber - 2;
      }
      if (this.currentPageNumber + 2 >= this.totel) {
        this.right_limit = this.totel;
      } else {
        this.right_limit = this.currentPageNumber + 2;
      }
    },
    changePage(index) {
      if (index == this.currentPageNumber) return;
      this.currentPageNumber = index;
      if (this.currentPageNumber - 2 <= 1) {
        this.left_limit = 1;
      } else {
        this.left_limit = this.currentPageNumber - 2;
      }
      if (this.currentPageNumber + 2 >= this.totel) {
        this.right_limit = this.totel;
      } else {
        this.right_limit = this.currentPageNumber + 2;
      }
      getArticalListInfo({ currentPage: this.currentPageNumber, pageSize: 10 }).then(res => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.$store.commit('getAllArticalListInfo',res.data.arr)
        this.$bus.$emit('getListInfo',res.data.arr)
        this.backToTop()
      });
    },
    back() {
      if (this.currentPageNumber - 1 < 1) {
        return;
      } else {
        this.currentPageNumber--;
        this.initPage();
        getArticalListInfo({
          currentPage: this.currentPageNumber,
          pageSize: 10
        }).then(res => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.$store.commit('getAllArticalListInfo',res.data.arr)
          this.$bus.$emit('getListInfo',res.data.arr)
          this.backToTop()
        });
      }
    },
    go() {
      if (this.currentPageNumber + 1 > this.totel) {
        return;
      } else {
        this.currentPageNumber++;
        this.initPage();
        getArticalListInfo({
          currentPage: this.currentPageNumber,
          pageSize: 10
        }).then(res => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.$store.commit('getAllArticalListInfo',res.data.arr)
          this.$bus.$emit('getListInfo',res.data.arr)
          this.backToTop()
        });
      }
    },
    backToTop(){
      document.getElementById("backToMe").scrollIntoView(true);
      this.$bus.$on('showBack',this.showBack);
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  button {
    width: 30px;
    height: 30px;
    background-color: #ecdbe2;
    border-radius: 3px;
    cursor: pointer;
    border: 0px;
  }
  .item {
    display: inline-block;
    width: 1.875rem;
    height: 1.875rem;
    background-color: #ecdbe2;
    text-align: center;
    line-height: 1.875rem;
    margin-left: 1.25rem;
    border-radius: 0.1875rem;
    cursor: pointer;
  }
  .active_item {
    display: inline-block;
    width: 1.875rem;
    height: 1.875rem;
    background-color: #b291b4;
    text-align: center;
    line-height: 1.875rem;
    margin-left: 1.25rem;
    border-radius: 0.1875rem;
    cursor: pointer;
  }
}
</style>