<template>
  <div class="comtainer">
    <div class="container">
      <div class="container_left">
        <el-card class="card_top_left">
          <el-tag
            :type="item.label_color"
            v-for="item in label.label_list"
            :key="item"
          >{{ item.label_name}}</el-tag>
        </el-card>
        <el-card class="card_bottom_left">
          <el-row :span="24">
            <el-col :span="6" style="line-height:2.5rem">添加新标签:</el-col>
            <el-col :span="6">
              <el-input v-model="label.label_name"></el-input>
            </el-col>
            <el-col :span="3" style="line-height:2.5rem;">
              <span style="float: right;margin-right:.5rem">颜色:</span>
            </el-col>
            <el-col :span="3">
              <el-select v-model="label.label_color" placeholder="请选择">
                <el-option
                  v-for="item in label.labelColorList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-button @click="addLabel()">确认添加</el-button>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :span="6" style="line-height:2.5rem">进行搜索:</el-col>
            <el-col :span="6">
              <el-input v-model="label.searchInfo"></el-input>
            </el-col>
            <el-col :span="3">
              <el-button @click="startSearch()">搜索</el-button>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :span="6" style="line-height:2.5rem">请选择:</el-col>
            <el-col :span="6" v-if="classifySearchSuccess">
              <el-select v-model="label.searchListInfo" placeholder="请选择">
                <el-option
                  v-for="item in label.searchList"
                  :key="item.label_name"
                  :label="item.label_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :span="6" style="line-height:2.5rem">修改为:</el-col>
            <el-col :span="6">
              <el-input v-model="label.modifyLabelName"></el-input>
            </el-col>
            <el-col :span="6">
              <el-button @click="modifyForLabel()">确认</el-button>
            </el-col>
          </el-row>
          <el-row :span="24">
            <el-col :span="6" style="line-height:2.5rem">是否进行删除:</el-col>
            <el-col :span="5">
              <el-button @click="confirmDelete()">确认删除</el-button>
            </el-col>
          </el-row>
        </el-card>
      </div>
      <div class="container_right">
        <el-card class="card_classify_display">
          <el-row :span="24">
            <el-col :span="3" style="line-height:2.5rem">添加分类:</el-col>
            <el-col :span="6">
              <el-input v-model="addRootClassifyData" placeholder="请设置分类名"></el-input>
            </el-col>
            <el-col :span="1">
              <el-button @click="addRootClassify()">确认</el-button>
            </el-col>
          </el-row>
          <el-row :span="24" v-if="rightnowChecked">
            <el-col :span="4" style="line-height:2.5rem">添加下级节点:</el-col>
            <el-col
              :span="20"
              style="line-height:2.5rem"
            >当前选中"{{rightnowCheckedInfo.classify_name }}"</el-col>
          </el-row>
          <el-row :span="24" v-if="rightnowChecked">
            <el-col :span="3" style="line-height:2.5rem">添加方向:</el-col>
            <el-col :span="4" style="line-height:2.5rem">
              <el-select v-model="value3" placeholder="请选择">
                <el-option
                  v-for="item in chooseTier"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="5" style="line-height:2.5rem">设置名称为:</el-col>
            <el-col :span="5">
              <el-input v-model="addNomralClassify" placeholder="请设置分类名"></el-input>
            </el-col>
            <el-col :span="3" style="line-height:2.5rem">
              <el-button @click="confirmedToAdd()">确认添加</el-button>
            </el-col>
          </el-row>
          <el-row :span="24" v-if="rightnowChecked">
            <el-col :span="4" style="line-height:2.5rem">修改选中节点:</el-col>
            <el-col :span="6">
              <el-input v-model="modifyClassifyName" placeholder="修改内容"></el-input>
            </el-col>
            <el-col :span="6">
              <el-button @click="confiremedToModify()">确认修改</el-button>
            </el-col>
          </el-row>
          <el-row :span="24" v-if="rightnowChecked">
            <el-col :span="4" style="line-height:2.5rem">删除选中节点:</el-col>
            <el-col :span="3">
              <el-button @click="confiremedToDelete(1)">是</el-button>
            </el-col>
            <el-col :span="3">
              <el-button @click="confiremedToDelete(2)">否</el-button>
            </el-col>
          </el-row>
          <el-row id="classify_father">
            <div class="classify_bottom" id="classify_children">
              <ul class="classOneTier" v-for="(item,index) in classifyList" :index="item">
                <li class="classOneTierText" @click="chooseThisClassify($event,item)">
                  <div class="oneTierShowBox">
                    <div class="childrenOnTier">{{item.classify_name}}</div>
                    <div class="childrenOnTier">{{item.count}}</div>
                  </div>
                </li>
                <Classify :childrenList="item.children" :homeToView="false"/>
              </ul>
            </div>
          </el-row>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  addrootclassifyOne,
  getallclassifylist,
  getallclassifylistOne,
  addnomralclassify,
  modifyClassify,
  deleteClassify
} from "@/api/manage/classify/index";
import {
  addLabel,
  deleteLabel,
  updateLabel,
  searchLabel,
  getAllLabelList
} from "@/api/manage/label/index";
export default {
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        },
        {
          value: "选项3",
          label: "蚵仔煎"
        },
        {
          value: "选项4",
          label: "龙须面"
        },
        {
          value: "选项5",
          label: "北京烤鸭"
        }
      ],
      addRootClassifyData: "", //绑定添加分类input
      classifySearchSuccess: true, //搜索完成之后就显示搜索出来的标签在select中然后进行操作,
      classifyList: [],
      rightnowCheckedInfo: null, //当前选中的这个分类节点的信息
      rightnowChecked: false,
      chooseTier: [
        {
          id: 1,
          value: "T",
          label: "同级"
        },
        {
          id: 2,
          value: "X",
          label: "下级"
        }
      ],
      addNomralClassify: "",
      value3: "",
      modifyClassifyName: "",
      label: {
        //储存关于label的变量
        label_list: [],
        label_color: "",
        label_name: "",
        labelColorList: [
          {
            value: "",
            label: "蓝色"
          },
          {
            value: "success",
            label: "绿色"
          },
          {
            value: "info",
            label: "灰色"
          },
          {
            value: "warning",
            label: "棕色"
          },
          {
            value: "danger",
            label: "红色"
          }
        ],
        searchInfo: "",
        searchListInfo: "",
        searchList: [],
        modifyLabelName: ""
      }
    };
  },
  mounted() {
    this.getAllClassifyList();
    this.$bus.$on("getRightnowCheckInfo", this.getRightnowCheckInfo);
    this.getAllLabelList();
  },
  beforeDestroy() {
    this.$bus.$off("getRightnowCheckInfo");
  },
  methods: {
    confirmDelete() {
      if (this.label.searchListInfo == "") {
        this.$message({ message: "请选择需要删除的标签", type: "warning" });
        return;
      }
      deleteLabel(this.label.searchListInfo).then(
        res => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.$message({ messsage: res.message, type: "success" });
          this.label.searchInfo = "";
          this.label.searchList = [];
          this.label.modifyLabelName = "";
          this.label.searchListInfo = "";
        },
        err => {}
      );
    },
    modifyForLabel() {
      if (this.label.searchListInfo == "") {
        this.$message({ message: "请选中需要修改的label", type: "warning" });
        return;
      }
      let obj = {
        id: this.label.searchListInfo,
        label_name: this.label.modifyLabelName
      };
      updateLabel(obj).then(
        res => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.$message({ message: res.message, type: "success" });
          this.getAllLabelList();
          this.label.searchInfo = "";
          this.label.searchList = [];
          this.label.modifyLabelName = "";
          this.label.searchListInfo = "";
        },
        err => {}
      );
    },
    startSearch() {
      if (this.label.searchInfo.replace(/\s+/g, "") == "") {
        this.$message({ message: "搜索内容不能为空", type: "warning" });
        return;
      }
      searchLabel(this.label.searchInfo).then(
        res => {
          if (res.code != 200) {
            this.$message().err(res.message);
            return;
          }
          this.label.searchList = res.data;
        },
        err => {}
      );
    },
    addLabel() {
      if (this.label.label_name.replace(/\s+/g, "") == "") {
        this.$message({
          message: "添加内容不能为空",
          type: "warning"
        });
        return;
      }
      let obj = {
        label_name: this.label.label_name,
        label_color: this.label.label_color
      };
      addLabel(obj).then(
        res => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.$message({ message: res.message, type: "success" });
          this.getAllLabelList();
        },
        err => {}
      );
    },
    /**
     * 添加新的分类,只是添加新的分类,分类下面的节点不会添加
     */
    addRootClassify() {
      let obj = {
        name: this.addRootClassifyData,
        root: true
      };
      if (obj.name == "") {
        this.$message({
          message: "添加项不能为空",
          type: "warning"
        });
        return;
      }
      addrootclassifyOne(obj).then(res => {
        if (res.code != 200) {
          alert(res.message);
          return;
        }
        getallclassifylist().then(
          resOne => {
            if (resOne.code != 200) {
              alert(res.message);
              return;
            }
            this.$message({
              message: resOne.message,
              type: "success"
            });
            this.getAllClassifyList();
            this.addRootClassifyData = "";
          },
          errOne => {}
        );
      });
    },
    /**
     * 获取所有的分类信息进行渲染
     */
    getAllClassifyList() {
      getallclassifylistOne().then(
        resOne => {
          if (resOne.code != 200) {
            this.$message.error(resOne.message);
            return;
          }
          this.classifyList = resOne.data.totalList;
        },
        errOne => {}
      );
    },
    /**
     * 置空别的元素背景色,设置当前元素的背景色
     * @param {*} event 默认事件
     * @param {*} thisCheckInfo 当前选中元素对应的信息
     */
    chooseThisClassify(event, thisCheckInfo) {
      let length = document.getElementsByClassName("classTwoTierText").length;
      let length1 = document.getElementsByClassName("classOneTierText").length;
      for (let i = 0; i < length; i++) {
        document.getElementsByClassName("classTwoTierText")[
          i
        ].style.backgroundColor = "#ffffff";
      }
      for (let i = 0; i < length1; i++) {
        document.getElementsByClassName("classOneTierText")[
          i
        ].style.backgroundColor = "#ffffff";
      }
      event.target.parentNode.parentNode.style.backgroundColor = "#afb4db";
      this.rightnowChecked = true;
      this.rightnowCheckedInfo = thisCheckInfo;
    },
    /**
     * 点击确认添加分类,可以添加任意节点包括分类
     */
    confirmedToAdd() {
      console.log(this.rightnowCheckedInfo, this.value3);
      if (this.value3 === "") {
        this.$message({
          message: "请选择添加方向",
          type: "warning"
        });
        return;
      }
      let obj = null;
      ;
      if (this.value3 === "T") {
        obj = {
          classify_name: this.addNomralClassify,
          father_id: this.rightnowCheckedInfo.father_id,
          root: this.rightnowCheckedInfo.root
        };
      } else {
        obj = {
          classify_name: this.addNomralClassify,
          father_id: this.rightnowCheckedInfo.id,
          root: 0
        };
      }
      addnomralclassify(obj).then(
        res => {
          if (res.code != 200) {
            alert(res.message);
            return;
          }
          this.addNomralClassify = "";
          this.value3 = "";
          this.getAllClassifyList();
        },
        err => {
          return err;
        }
      );
    },
    getRightnowCheckInfo(info, istrue) {
      this.rightnowCheckedInfo = info;
      this.rightnowChecked = istrue;
    },
    confiremedToModify() {
      if (this.modifyClassifyName.replace(/\s+/g, "") == "") {
        this.$message({
          message: "输入不能为空",
          type: "warning"
        });
        return;
      }
      let obj = {
        id: this.rightnowCheckedInfo.id,
        classify_name: this.modifyClassifyName
      };
      modifyClassify(obj).then(res => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.$message({
          message: res.message,
          type: "success"
        });
        this.getAllClassifyList();
      });
    },
    confiremedToDelete(index) {
      switch (index) {
        case 1:
          if (!confirm("确认后将删除此分类下所有分类关键词")) {
            return;
          }
          deleteClassify(this.rightnowCheckedInfo.id).then(res => {
            if (res.code != 200) {
              this.$message.error(res.message);
              return;
            }
            this.$message({
              message: res.message,
              type: "success"
            });
            this.getAllClassifyList();
          });
          break;
        case 2:
          break;
      }
    },
    getAllLabelList() {
      getAllLabelList().then(
        res => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.label.label_list = res.data;
          this.label.label_name = "";
        },
        err => {}
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.comtainer {
  position: relative;
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    .container_left {
      width: 48%;
      height: 100%;
      position: relative;
      .card_top_left {
        width: 98%;
        height: 30rem;
        position: absolute;
        top: 0.625rem;
        left: 1rem;
        .el-tag {
          margin-left: 1rem;
        }
      }
      .card_bottom_left {
        width: 98%;
        height: 24rem;
        position: absolute;
        left: 1rem;
        top: 31.875rem;
      }
    }
    .container_right {
      width: 50%;
      height: 100%;
      margin-left: 2rem;
      position: relative;
      .card_classify_display {
        width: 98%;
        height: auto;
        position: absolute;
        top: 0.625rem;
        right: 1rem;
        .classify_bottom {
          height: auto;
          .classOneTier {
            list-style: none;
            padding-left: 1rem;
            margin-left: 1rem;
            margin-top: 1rem;
            font-size: 1.1rem;
            .classOneTierText {
              height: 1.8rem;
              .oneTierShowBox {
                border-radius: 0.3rem;
                display: flex;
                .childrenOnTier:nth-child(1) {
                  line-height: 1.8rem;
                  padding-left: 0.4rem;
                  flex: 1;
                }
                .childrenOnTier:nth-child(2) {
                  line-height: 1.8rem;
                  flex: 1;
                  text-align: right;
                  padding-right: 0.4rem;
                }
              }
              .oneTierShowBox:hover {
                background-color: #afb4db;
                cursor: pointer;
              }
            }
          }
          .labelList {
            margin-left: 1rem;
            overflow: hidden;
            .list_item {
              display: inline-block;
              border: 2px solid #afb4db;
              margin: 0.3rem;
              border-radius: 0.2rem;
              a {
                text-decoration: none;
                color: black;
              }
            }
          }
        }
      }
    }
  }

  .el-row {
    margin-top: 2rem;
  }
}
</style>
