<template>
  <div class="container">
    <div class="list_top" id="classiy_name_position">
        <span style="padding-left:1rem;font-weight:600;">分类“{{classifyName}}”下的文章：</span>
    </div>
    <div class="list">
        <div class="list_item" v-for="(item,index) in articalList" :key="item.artical_title" @click="jumpToSeeArtical(item,index)">{{ item.artical_title }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classifyName:''
    };
  },
  mounted(){
    this.classifyName=this.$route.params.name
    document.getElementById("classiy_name_position").scrollIntoView(true)
  },
  computed:{
    articalList(){
      return this.$store.state.articalInRightnowClickClassify
    }
  },
  watch:{
    $route(to,from){
      this.classifyName=to.path.split('/')[2]
      document.getElementById("classiy_name_position").scrollIntoView(true)
    }
  },
  methods:{
    jumpToSeeArtical(item,index){
      this.$router.push({path:`/showarticalinfo/${item.id}`})
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 50vw;
  display: flex;
  flex-direction: column;
  .list_top {
    margin-left: 2vw;
    opacity: 0.8;
    width: 46vw;
    height: 2rem;
    line-height: 2rem;
    background-color: #feeeed;
    border-radius: 0.6rem;
    margin-top: 1.2rem;
    font-size: 1.3rem;
  }
  .list{
    margin-left: 2vw;
    opacity: 0.8;
    width: 46vw;
    height: auto;
    background-color: #feeeed;
    border-radius: 0.6rem;
    margin-top: 1.2rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .list_item{
        height: 3rem;
        padding-left: 1rem;
        font-size: 1.5rem;
        line-height: 3rem;
        width: 90%;
        border-bottom: 1px dashed grey;
        border-radius: 15px;
    }
    .list_item:hover{
      background-color: rgb(191,187,215);
    }
  }
}
</style>