<template>
  <div>
    <el-container>
      <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
        <el-menu :default-openeds="['1', '3']">
            <el-menu-item-group>
              <el-menu-item index="1-1" @click="jumpToNeedToSeePage(1)">首页-路由</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="1-2" @click="jumpToNeedToSeePage(2)">分类-标签</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="1-3" @click="jumpToNeedToSeePage(3)">文章发布</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="1-4" @click="jumpToNeedToSeePage(4)">文章管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="1-5" @click="jumpToNeedToSeePage(5)">说说管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="1-6" @click="jumpToNeedToSeePage(6)">友链管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="1-7" @click="jumpToNeedToSeePage(7)">关于管理</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="1-8" @click="jumpToNeedToSeePage(8)">留言管理</el-menu-item>
            </el-menu-item-group>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods:{
    jumpToNeedToSeePage(index){
      switch(index){
        case 1: this.$router.push('/manage_home');break;
        case 2: this.$router.push('/labelandclassify');break;
        case 3: this.$router.push('/articalmanage');break;
        case 4: this.$router.push('/reviseArticle');break;
        case 5: this.$router.push('/talkToUs'); break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.el-container {
  padding: 0px;
  margin: 0px;
}
.el-aside {
  color: #333;
  line-height: 95.6vh;
  height: 100vh;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  padding: 15px;
}
.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>