<template>
  <div class="mainContent">
    <!-- 这里存展示这个评论 -->
    <div class="displayComment">
      <span class="iconfont icon-duihua">
        <span>评论</span>
      </span>
    </div>
    <hr style="margin-top: .5rem;margin-bottom:.5rem" />
    <!-- 这里填写用户发送信息的数据 -->
    <div class="selectCommenterInfo">
      <div class="collect_TX">
        <img src="../../assets/homePic/sangouyu.png" alt />
      </div>
      <div class="collect_Info">
        <el-row>
          <el-col :span="8">
            <div style="width: 90%">
              <el-input placeholder="请输入昵称" v-model="publicCommentInfo.common_name"></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="width: 90%">
              <el-input placeholder="邮箱" v-model="publicCommentInfo.email"></el-input>
            </div>
          </el-col>
          <el-col :span="8" v-if="startToApplyPerson">
            <div style="width: 90%">
              <span>回复:</span>
              <a :href="applyPersonId">{{applyPerson}}</a>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="width: 96.5%; margin-top: 0.5rem">
              <el-input type="textarea" placeholder="请输入内容" v-model="publicCommentInfo.common_text"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="width: 96.5%; margin-top: 0.5rem">
              <div style="float: right">
                <el-button @click="commit(1)" v-if="hiddenCommit">提交</el-button>
                <el-button @click="commit(2)" v-else>回复</el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 这里存放评论总数 -->
    <div class="comment_module">
      <el-row>
        <span>{{ articalInfo.comment_number }}条评论</span>
      </el-row>
      <div class="commentShowList">
        <ul class="topUl" v-for="item in commentListInfo" :key="item">
          <li>
            <div style="display: flex">
              <div style="flex: 1">
                <img src="../../assets/homePic/ssw.png" alt />
              </div>
              <div style="flex: 7" :id="item.id">
                <el-row>
                  <span>{{item.common_name}}</span>
                </el-row>
                <el-row class="middle_item">
                  <label style="font-size: .6rem;">{{ item.common_time }}</label>
                </el-row>
                <el-row>
                  <label style="background-color: #f5f9ff;">{{ item.common_text }}</label>
                </el-row>
                <el-row>
                    <label style="background-color: #f5f9ff;">
                      <div href="#commentAnchorPoint" @click="startToApply(item)">回复</div>
                    </label>
                  </el-row>
              </div>
            </div>
          </li>
          <ul class="secondUl" v-for="itemOne in item.commentFllowList" :key="itemOne">
            <li>
              <div style="display: flex">
                <div style="flex: 1">
                  <img src="../../assets/homePic/longMao.jpg" alt />
                </div>
                <div style="flex: 7" :id="itemOne.id">
                  <el-row>
                    <span>{{ itemOne.common_name }}</span>
                  </el-row>
                  <el-row class="middle_item">
                    <label style="font-size: .6rem;">{{ itemOne.common_time }}</label>
                  </el-row>
                  <el-row>
                    <label style="background-color: #f5f9ff;">
                      <a href="#">@{{ itemOne.applyto }}</a>
                      &nbsp;&nbsp;{{ itemOne.common_text }}
                    </label>
                  </el-row>
                  <el-row>
                    <label style="background-color: #f5f9ff;">
                      <div href="#commentAnchorPoint" @click="startToApply(itemOne)">回复</div>
                    </label>
                  </el-row>
                </div>
              </div>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {
  publicComment,
  getArticalCommentList
} from "@/api/reception/comment/index";
export default {
  props: ["articalInfo"],
  data() {
    return {
      hiddenCommit:true,
      startToApplyPerson:false,
      applyPerson:'',
      applyPersonId:'',
      commentListInfo: [],
      thisCommentHeight: null,
      SSid: null,
      publicCommentInfo: {
        artical_id: 0,  //当组件开始渲染的时候就会被赋值,缺点是不能够同时出现多个这样的comment组件
        applyto_id: 0, //默认为0,等点击回复的时候才会发生改变
        common_text: "",
        email: "",
        common_name: "",
        root_id: 1 //为1表示根节点,0不为根节点
      }
    };
  },
  mounted() {
    this.publicCommentInfo.artical_id = this.articalInfo.id; //赋值
    this.$bus.$on("showOrHiddenComment", this.showOrHiddenComment); //初始化点击评论的事件
    this.getAllCommentInfoList(); //调接口获取这个所有的评论信息列表,去渲染,也是就是上面的这个commentListInfo,上面那个现在还是假的
  },
  methods: {
    showOrHiddenComment(info) {
      let dom = document.getElementsByClassName("mainContent")[0];
      if (!info) {
        dom.style.height = this.thisCommentHeight + "px";
      } else {
        dom.style.height = 0 + "px";
      }
    },
    // 在这里拿到传送过来的说说id去请求评论列表,这里是假的数据
    getAllCommentInfoList() {
      getArticalCommentList(this.articalInfo.id).then(res => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.commentListInfo = res.data;
        this.$nextTick(() => {
          let dom = document.getElementsByClassName("mainContent")[0];
          this.thisCommentHeight = dom.offsetHeight;
          dom.style.height = 0 + "px";
        });
      });
    },
    commit(index) {
      if(index==1){
        this.publicCommentInfo.root_id = 1
      }
      if(index==2){
        this.publicCommentInfo.root_id=0
      }
      if (this.publicCommentInfo.root_id == 1) { //这里前端需要做一些东西
      }
      publicComment(this.publicCommentInfo).then(res => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.$message({
          message: res.message,
          type: "success"
        });
        this.commentListInfo = res.data
        this.$nextTick(() => {
          let dom = document.getElementsByClassName("mainContent")[0];
          dom.style.height = dom.offsetHeight +100+ "px";
        });
      });
    },
    startToApply(item){
      this.hiddenCommit=false
      this.publicCommentInfo.applyto_id=item.id
      this.$bus.$emit('jumpToPoint')
      this.applyPerson=`@${item.common_name}`
      this.applyPersonId=`#${item.id}` //用来做锚点跳转的逻辑
      this.startToApplyPerson=true  //控制回复按钮的显示影藏,不过这个还没加取消逻辑
    }
  }
};
</script>
<style lang="scss" scoped>
.mainContent {
  transition: height 0.1s linear;
  overflow: hidden;
  width: 44vw;
  height: auto;
  margin-left: 1.5vw;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  .displayComment {
    .icon-duihua {
      color: blue;
      font-size: 1.5rem;
      span {
        font-size: 1.5rem;
        margin-left: 0.5rem;
        color: black;
      }
    }
  }
  .selectCommenterInfo {
    display: flex;
    margin-top: 1rem;
    .collect_TX {
      flex: 1;
      img {
        width: 4rem;
        height: 4rem;
        background-color: red;
        border-radius: 2rem;
      }
    }
    .collect_Info {
      flex: 8;
    }
  }
  .comment_module {
    .el-row {
      span {
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
    .commentShowList {
      .topUl {
        margin-top: 2rem;
        list-style: none;
        img {
          width: 4rem;
          height: 4rem;
          border-radius: 2rem;
        }
        .middle_item {
          margin-top: 0.3rem;
          margin-bottom: 0.3rem;
        }
        .secondUl {
          width: 92.5%;
          margin-left: 4rem;
          list-style: none;
          li {
            margin-top: 1rem;
            img {
              width: 4rem;
              height: 4rem;
              border-radius: 2rem;
            }
          }
          .middle_item {
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
          }
        }
      }
    }
  }
}
</style>
