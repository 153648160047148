<!-- 说说页面 -->
<template>
  <div class="middleContainer">
    <div class="itemList" v-for="(item,index) in sendTorightNowComment" :key="item">
      <div class="item_public_time"><span class="iconfont icon-fasong"></span> {{ item.SSInfo.publicTime }}</div>
      <div class="item_public_container">
        <p>&nbsp;&nbsp;&nbsp;&nbsp;{{ item.SSInfo.SStext }}</p>
        <div class="imgContainer">
          <img src="../../assets/homePic/fengjin.jpg" alt="" :class="showImage" @click="showImageToSee()">
          <img src="../../assets/homePic/longMao.jpg" alt="" :class="showImage">
          <img src="../../assets/homePic/sangouyu.png" alt="" :class="showImage">
        </div>
      </div>
      <div class="item_interaction">
        <div class="item_interaction_one"><span class="iconfont icon-xihuan"><span>{{ item.SSInfo.likeCount }}</span></span></div>
        <div class="item_interaction_two" @click="fold(index)"><span class="iconfont icon-duihua"><span>{{ item.SSInfo.commmentCount }}</span></span></div>
      </div>
      <Comment :commentIndex="index" :id="item.id"></Comment>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showImage:'oneImg',
      sendTorightNowComment:[
        {
          cloose:false, //控制这个点击评论的收缩
          id:'1', //当前说说的id
          SSindex:0,
          SSInfo: { //储存这个说说的具体信息
              publicTime:'2023/9/10',
              SStext:'看这里看这里看这里看这里看这里看这里看这里看这里看这里看这里看这里看这里看这里看这里看这里看这里看这里看这里...',
              imgPath:[{},{},{},{}],
              likeCount:'23',
              commmentCount:'16'
            }
        },
        {
          cloose:false, //控制这个点击评论的收缩
          id:'1', //当前说说的id
          SSindex:1,
          SSInfo: { //储存这个说说的具体信息
              publicTime:'2023/9/10',
              SStext:'嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨嗨',
              imgPath:[{},{},{},{}],
              likeCount:'23',
              commmentCount:'16'
            }
        }
      ],
    };
  },
  methods:{
    showImageToSee(){
      console.log('点击了');
    },
    fold(index){
      if(!this.sendTorightNowComment[index].cloose){
        this.$bus.$emit("showOrHiddenComment",this.sendTorightNowComment[index])
        this.sendTorightNowComment[index].cloose=true
      }else{
        this.$bus.$emit("showOrHiddenComment",this.sendTorightNowComment[index])
        this.sendTorightNowComment[index].cloose=false
      }
      
    }
  }
};
</script>
<style lang="scss" scoped>
.middleContainer {
  width: 50vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .itemList{
    width: 46vw;
    background-color: #feeeed;
    border-radius: .6rem;
    margin-top: 1.2rem;
    display: flex;
    flex-direction: column;
    .item_public_time{
      padding-top: 1rem;
      padding-left: 1rem;
      padding-bottom: 1rem;
    }
    .item_public_container{
      padding-top: 1rem;
      background-color: #f5f5f5;
      width: 44vw;
      margin-left: 1vw;
      padding-bottom:1rem;
      margin-bottom:1rem;
      .imgContainer{
        display:flex;
        flex-wrap: wrap;
        overflow: scroll;
        .oneImg{
          width: 51rem;
          height: 25rem;
          margin-top: 1rem;
          margin-left: 1rem;
          cursor: pointer;
        }
        .exceedTwoImg{
          width: 25rem;
          height: 25rem;
          margin-top: 1rem;
          margin-left: 1rem;
          cursor: pointer;
        }
      }
    }
    .item_interaction{
      margin-left: 2rem;
      display: flex;
      margin-bottom: 1rem;
      .item_interaction_one{
        span{
          font-size: 1.1rem;
          span{
            margin-left: .5rem;
            font-size: 1rem;
          }
        }
      }
      .item_interaction_two{
        margin-left: 1rem;
        span{
          font-size: 1.1rem;
          span{
            margin-left: .5rem;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
