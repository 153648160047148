<template>
  <div class="mainContent">
    <!-- 这里存展示这个评论 -->
    <div class="displayComment" v-if="fatherInfo">
      <span class="iconfont icon-duihua"><span>评论</span></span>
    </div>
    <!-- 这里填写用户发送信息的数据 -->
    <div class="selectCommenterInfo">
      <div class="collect_TX">
        <img src="../../assets/homePic/sangouyu.png" alt="" />
      </div>
      <div class="collect_Info">
        <el-row>
          <el-col :span="8">
            <div style="width: 90%">
              <el-input placeholder="请输入内容"></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="width: 90%">
              <el-input placeholder="邮箱"></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="width: 90%">
              <el-input placeholder="网址"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="width: 96.5%; margin-top: 0.5rem">
              <el-input type="textarea"></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="width: 96.5%; margin-top: 0.5rem">
              <div style="float: right"><el-button>提交</el-button></div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 这里存放评论总数 -->
    <div class="comment_module">
      <el-row>
        <span>{{ commentListInfo.length }}条评论</span>
      </el-row>
      <div class="commentShowList">
        <ul class="topUl" v-for="(item,index) in commentListInfo" :key="item">
          <li>
            <div style="display: flex">
              <div style="flex: 1">
                <img src="../../assets/homePic/ssw.png" alt="" />
              </div>
              <div style="flex: 7">
                <el-row>
                  <span>{{item.userName}}</span>
                </el-row>
                <el-row class="middle_item"> <label style="font-size: .6rem;">{{ item.commentTime }}</label></el-row>
                <el-row> <label style="background-color: #f5f9ff;">{{ item.commentContent }}</label> </el-row>
              </div>
            </div>
          </li>
          <ul class="secondUl" v-for="(itemOne,indexOne) in item.commentFllowList" :key="itemOne">
            <li>
              <div style="display: flex">
                <div style="flex: 1">
                  <img src="../../assets/homePic/longMao.jpg" alt="" />
                </div>
                <div style="flex: 7">
                  <el-row>
                    <span>{{ itemOne.userName }}</span>
                  </el-row>
                  <el-row class="middle_item"> <label style="font-size: .6rem;">{{ itemOne.commentTime }}</label> </el-row>
                  <el-row> <label style="background-color: #f5f9ff;"><a href="#">@{{ itemOne.applyTo }}</a>&nbsp;&nbsp;{{ itemOne.commentContent }}</label></el-row>
                </div>
              </div>
            </li>
          </ul>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['commentIndex','id'],
  data() {
    return {
      fatherInfo: false, //用这个变量来控制在这个评论标题是否显示,从父组件那里传送过来
      commentListInfo: null,
      thisCommentHeight:null,
      SSid:null
    };
  },
  mounted(){
    this.$bus.$on('showOrHiddenComment',this.showOrHiddenComment) //初始化点击评论的事件
    this.getAllCommentInfoList() //调接口获取这个所有的评论信息列表,去渲染,也是就是上面的这个commentListInfo,上面那个现在还是假的
    this.$nextTick(()=>{
      let dom=document.getElementsByClassName('mainContent')[this.commentIndex]
      this.thisCommentHeight=dom.offsetHeight
      dom.style.height=0+'px'
    })
  },
  methods:{
    showOrHiddenComment(info){
      let dom=document.getElementsByClassName('mainContent')[info.SSindex]
      if(!info.cloose){
        dom.style.height=this.thisCommentHeight+'px'
      }else{
        dom.style.height=0+'px'
      }
    },
    // 在这里拿到传送过来的说说id去请求评论列表,这里是假的数据
    getAllCommentInfoList(){
      this.commentListInfo=[
        {
          //这里还需要返回一个图片的访问路径,这里先不写.
          userId: "22",
          userName: "天才小火龙",
          userAddress: "天堂",
          userHttps: "www.baidu.com",
          commentTime: "2023/9/10",
          commentContent: "这里是评论的内容,嘻嘻休息欧",
          commentFllowList: [
            {
              applyToUserId: "22",
              applyTo: "天才小火龙",
              userId: "34",
              userAddress: "天堂",
              userHttps: "www.baidu.com",
              userName: "读书读傻了",
              commentTime: "2023/9/11",
              commentContent: "你说的不对,我是个读书读傻了的人",
            },
            {
              applyToUserId: "22",
              applyTo: "天才小火龙",
              userId: "34",
              userAddress: "天堂",
              userHttps: "www.baidu.com",
              userName: "读书读傻了",
              commentTime: "2023/9/11",
              commentContent: "你说的不对,我是个读书读傻了的人",
            },
            {
              applyToUserId: "22",
              applyTo: "天才小火龙",
              userId: "34",
              userAddress: "天堂",
              userHttps: "www.baidu.com",
              userName: "读书读傻了",
              commentTime: "2023/9/11",
              commentContent: "你说的不对,我是个读书读傻了的人",
            },
            {
              applyToUserId: "22",
              applyTo: "天才小火龙",
              userId: "34",
              userAddress: "天堂",
              userHttps: "www.baidu.com",
              userName: "读书读傻了",
              commentTime: "2023/9/11",
              commentContent: "你说的不对,我是个读书读傻了的人",
            }
          ],
        },
        {
          //这里还需要返回一个图片的访问路径,这里先不写.
          userId: "22",
          userName: "天才小火龙",
          userAddress: "天堂",
          userHttps: "www.baidu.com",
          commentTime: "2023/9/10",
          commentContent: "这里是评论的内容,嘻嘻休息欧",
          commentFllowList: [
            {
              applyToUserId: "22",
              applyTo: "天才小火龙",
              userId: "34",
              userAddress: "天堂",
              userHttps: "www.baidu.com",
              userName: "读书读傻了",
              commentTime: "2023/9/11",
              commentContent: "你说的不对,我是个读书读傻了的人",
            },
            {
              applyToUserId: "22",
              applyTo: "天才小火龙",
              userId: "34",
              userAddress: "天堂",
              userHttps: "www.baidu.com",
              userName: "读书读傻了",
              commentTime: "2023/9/11",
              commentContent: "你说的不对,我是个读书读傻了的人",
            },
            {
              applyToUserId: "22",
              applyTo: "天才小火龙",
              userId: "34",
              userAddress: "天堂",
              userHttps: "www.baidu.com",
              userName: "读书读傻了",
              commentTime: "2023/9/11",
              commentContent: "你说的不对,我是个读书读傻了的人",
            },
            {
              applyToUserId: "22",
              applyTo: "天才小火龙",
              userId: "34",
              userAddress: "天堂",
              userHttps: "www.baidu.com",
              userName: "读书读傻了",
              commentTime: "2023/9/11",
              commentContent: "你说的不对,我是个读书读傻了的人",
            }
          ]
        }
    ]
    }
  }
};
</script>
<style lang="scss" scoped>
.mainContent {
  transition: height .1s linear;
  overflow: hidden;
  width: 44vw;
  height: auto;
  margin-left: 1.5vw;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  .displayComment {
    .icon-duihua {
      color: blue;
      font-size: 1.5rem;
      span {
        font-size: 1.5rem;
        margin-left: 0.5rem;
        color: black;
      }
    }
  }
  .selectCommenterInfo {
    display: flex;
    .collect_TX {
      flex: 1;
      img {
        width: 4rem;
        height: 4rem;
        background-color: red;
        border-radius: 2rem;
      }
    }
    .collect_Info {
      flex: 8;
    }
  }
  .comment_module {
    .el-row {
      span {
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
    .commentShowList {
      .topUl {
        margin-top: 2rem;
        list-style: none;
        img {
          width: 4rem;
          height: 4rem;
          border-radius: 2rem;
        }
        .middle_item{
          margin-top: .3rem;margin-bottom: .3rem;
        }
        .secondUl {
          width: 92.5%;
          margin-left: 4rem;
          list-style: none;
          li {
            margin-top: 1rem;
            img {
              width: 4rem;
              height: 4rem;
              border-radius: 2rem;
            }
          }
          .middle_item{
          margin-top: .3rem;margin-bottom: .3rem;
        }
        }
      }
    }
  }
}
</style>
