import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        articalInfo: null,
        allArticalListInfo: null,
        articalInRightnowClickClassify: null
    },
    mutations: {
        modifyArticalInfo(state, info) {
            state.articalInfo = info
        },
        getAllArticalListInfo(state, info) {
            state.allArticalListInfo = info
        },
        changeArticalInRightnowClickClassify(state, info) {
            state.articalInRightnowClickClassify = info
        }
    },
    actions: {},
    getters: {

    },
    modules: {
        
    },
    plugins: [createPersistedState()]
})
