<!-- 归档(点击首页导航栏文章出现的)页面 -->
<template>
  <div class="middleContainer">
    <div id="backToClassify"></div>
    <div class="container">
      <el-timeline :reverse="reverse">
        <el-timeline-item
          v-for="activity in activities"
          :key="activity.content"
          :timestamp="activity.timestamp"
          :id="activity.id"
          @click.native="handleChangeVideo(activity)"
        >
          {{ activity.content }}
        </el-timeline-item>
      </el-timeline>
      <div class="timeLineSlider" v-show="showShowMore">
        <div style="flex: 10;"></div>
        <div style="flex: 2;">
          <span class="iconfont icon-gengduo" @click="showMore()"></span>
        </div>
        <div style="flex: 10;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {getAllArticalListInfo} from '@/api/manage/artical/index'
// 保存所有的这个文章信息,因为下面需要做一个分页
let saveTheInitialDataReturnedByTheInterfaceRequest=null
let indexForShowMore=0
export default {
  data() {
    return {
      reverse: true,
      activities: [],
      showShowMore:true
    };
  },
  mounted(){
    document.getElementById("backToClassify").scrollIntoView(true)
    getAllArticalListInfo().then(res=>{
      if(res.code!=200){
        this.$message.error(res.message)
        return
      }
      if(res.data.length==0){
        this.$message.error('请先添加文章')
        return
      }
      saveTheInitialDataReturnedByTheInterfaceRequest=res.data
      let newArrOne=res.data.slice(0,10) //一次只展示10条文章内容
      let newArr=[]
      newArrOne.forEach(item=>{
        let timeStart=item.otherInfo.public_time.split('T')[0]
        let timeEnd=' '+item.otherInfo.public_time.split('T')[1].slice(0,8)
        let newObj={
          content:item.artical_title,
          id:item.id,
          timestamp:timeStart+timeEnd
        }
        newArr.unshift(newObj)
      })
      this.activities=newArr
    },err=>{})
  },
  methods: {
    handleChangeVideo(timelineInfo) {
      this.$router.push(`/showarticalinfo/${timelineInfo.id}`)
    },
    // 显示更多的时间线条,一次增加10条
    showMore(){
      let lastIndex=indexForShowMore*10+10-1
      indexForShowMore++
      let thisTimeIndex=indexForShowMore*10+10-1
      if(thisTimeIndex>saveTheInitialDataReturnedByTheInterfaceRequest.length-1){
        this.showShowMore=false;
        thisTimeIndex=saveTheInitialDataReturnedByTheInterfaceRequest.length
      }
      let newArr=saveTheInitialDataReturnedByTheInterfaceRequest.slice(lastIndex,thisTimeIndex)
      newArr.forEach(item=>{
        let timeStart=item.otherInfo.public_time.split('T')[0]
        let timeEnd=' '+item.otherInfo.public_time.split('T')[1].slice(0,8)
        let newObj={
          content:item.artical_title,
          id:item.id,
          timestamp:timeStart+timeEnd
        }
        this.activities.unshift(newObj)
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.middleContainer {
  width: 50vw;
  .container {
    margin-left: 2vw;
    opacity: 0.8;
    width: 46vw;
    height: auto;
    background-color: #feeeed;
    border-radius: 0.6rem;
    margin-top: 1.2rem;
    .el-timeline {
      width: 95%;
      margin-left: 2rem;
      padding-top: 2rem;
      font-size: 1.3rem;
      .el-timeline-item {
        padding-top: 2rem;
      }
      .el-timeline-item::v-deep {
        .el-timeline-item__content {
          cursor: pointer;
        }
        .el-timeline-item__content:hover {
          cursor: pointer;
          color: blue;
        }
      }
    }
    .timeLineSlider{
      width: 95%;
      margin-left: 2rem;
      padding-top: 2rem;
      padding-bottom: 1rem;
      font-size: 1.3rem;
      display: flex;
      span{
        cursor: pointer;
      }
      span:hover{
        color: blue;
      }
    }
  }
}
</style>
