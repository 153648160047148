<!-- 首页的根组件 -->
<template>
  <div class="mainContainer" v-on:scroll="addAnimation($event)">
    <div id="backTpTop"></div>
    <Header :class="saveHeaderOperateInfo.topNav" :size="size" style="z-index: 1000000;"></Header>
    <div :class="saveHeaderOperateInfo.showOrHidden"></div>
    <div class="main">
      <!--左侧 -->
      <div class="main_left"></div>
      <!-- 主题内容区域 -->
      <div class="main_middle">
        <div class="full_to_say" v-show="showMiddleBlock">
          <span :class="saveHeaderOperateInfo.textShow">{{
            otherInfoObj.page_title
          }}</span>
        </div>
        <div class="full_to_choose">
          <!-- 主题内容左侧区域 -->
          <div class="middle_left">
            <!-- 个人信息展示区域 -->
            <div class="selfIntroduce">
              <div class="self_one">
                <div class="self_one_item_one">
                  <img :src="otherInfoObj.image_path" alt="" />
                </div>
                <div class="self_one_item_two">
                  <div>{{ otherInfoObj.show_user_name }}</div>
                </div>
                <div class="self_one_item_three">
                  <div>{{ otherInfoObj.user_motto }}</div>
                </div>
                <div class="self_one_item_four">
                  <span class="iconfont icon-ditu-dibiao">{{
                    otherInfoObj.user_address
                  }}</span>
                </div>
              </div>
              <div class="self_two">
                <div class="self_two_item_one">
                  <div>文章</div>
                  <div>{{ otherInfoObj.allartical_count }}</div>
                </div>
                <div class="self_two_item_one">
                  <div>分类</div>
                  <div>{{ otherInfoObj.allclassify_count }}</div>
                </div>
                <div class="self_two_item_one">
                  <div>标签</div>
                  <div>{{ otherInfoObj.alllabel_count }}</div>
                </div>
              </div>
              <div class="self_three">
                <button>JoinuS</button>
              </div>
              <div class="self_four">
                <div class="public">
                  <a
                    class="iconfont icon-gitee-fill-round"
                    title="gitee"
                    href="https://gitee.com/strikersee"
                    target="_blank"
                  ></a>
                </div>
                <div class="public">
                  <a class="iconfont icon-QQ" title="联系QQ：1261318510"></a>
                </div>
                <div class="public">
                  <a
                    class="iconfont icon-weixin"
                    title="联系微信：zcc1261318510"
                  ></a>
                </div>
                <div class="public">
                  <a class="iconfont icon-xinhao" title="关于作者"></a>
                </div>
              </div>
            </div>
            <!-- 分类展示模块 -->
            <div class="classifyList">
              <div class="classify_top">
                <div
                  style="
                    flex: 1.5;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span class="iconfont icon-fenlei"></span>
                </div>
                <div style="flex: 5; align-items: center">
                  <span>分类</span>
                </div>
                <div style="flex: 2; align-items: center" class="more">
                  <div @click="jump(1)">更多</div>
                  <div>＞</div>
                </div>
              </div>
              <div class="classify_bottom">
                <ul
                  class="classOneTier"
                  v-for="item in classifyList"
                  :key="item.classify_name"
                >
                  <li class="classOneTierText" @click.stop="chooseThisClassify($event, item)">
                    <div class="oneTierShowBox">
                      <div class="childrenOnTier">{{ item.classify_name }}</div>
                      <div class="childrenOnTier">{{ item.count }}</div>
                    </div>
                  </li>
                  <ul
                    class="classTwoTier"
                    v-for="itemOne in item.children"
                    :key="itemOne.classify_name"
                  >
                    <li class="classTwoTierText" @click.stop="chooseThisClassify($event, itemOne)">
                      <div class="twoTierShowBox">
                        <div class="childrenOnTier">
                          {{ itemOne.classify_name }}
                        </div>
                        <div class="childrenOnTier">{{ itemOne.count }}</div>
                      </div>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
            <!-- 标签 -->
            <div class="classifyList">
              <div class="classify_top">
                <div
                  style="
                    flex: 1.5;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span class="iconfont icon-24gf-tags4"></span>
                </div>
                <div style="flex: 5; align-items: center">
                  <span>标签</span>
                </div>
                <div style="flex: 2; align-items: center" class="more">
                  <div @click="jump(2)">更多</div>
                  <div>＞</div>
                </div>
              </div>
              <div class="classify_bottom">
                <div class="labelList">
                  <div
                    class="list_item"
                    v-for="item in labelList"
                    :key="item.label_name"
                  >
                    <a href="#">{{ item.label_name }}</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- 友链 -->
            <div class="classifyList">
              <div class="classify_top">
                <div
                  style="
                    flex: 1.5;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span class="iconfont icon-link"></span>
                </div>
                <div style="flex: 5; align-items: center">
                  <span>友链</span>
                </div>
                <div style="flex: 2; align-items: center" class="more">
                  <div @click="jump(3)">更多</div>
                  <div>＞</div>
                </div>
              </div>
              <div class="classify_bottom">
                <ul class="classOneTier">
                  <li
                    class="classOneTierText"
                    v-for="item in friendList"
                    :key="item.name"
                  >
                    <div class="oneTierShowBox">
                      <div class="childrenOnTier">{{ item.name }}</div>
                      <div class="childrenOnTier">{{ item.link }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 主题内容中间区域 -->
          <router-view />
          <!-- 主题内容右侧区域 -->
          <div class="middle_right">
            <div class="commentList">
              <div class="classify_top">
                <div
                  style="
                    flex: 1.5;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <span class="iconfont icon-liuyan"></span>
                </div>
                <div style="flex: 5; align-items: center">
                  <span>网站资讯</span>
                </div>
                <div style="flex: 2; align-items: center" class="more">
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div class="classify_bottom">
                <ul class="classOneTier">
                  <li
                    class="classOneTierText"
                    v-for="item in websiteInfo"
                    :key="item.name"
                  >
                    <div class="oneTierShowBox">
                      <div class="childrenOnTier">{{ item.name }}</div>
                      <div class="childrenOnTier">{{ item.count }}</div>
                    </div>
                  </li>
                  <li class="classOneTierText">
                    <div class="oneTierShowBox">
                      <div class="childrenOnTier"></div>
                      <div class="childrenOnTier"></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="main_right"></div>
    </div>
    <div class="scrollToTop" @click="scrollToTopMyBlog()" v-show="showBackToTopButton">
      <div class="iconfont icon-shangchuan"></div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { getPartClassifyList } from "@/api/manage/classify";
import { getAllLabelList } from "@/api/manage/label";
import { getHeaderList } from "@/api/manage/homeotherInfo";
import { getModifyInfoList } from "@/api/manage/homeotherInfo";
import { countOfAccess } from "@/api/manage/login";
import { viewArtical } from "@/api/manage/artical/index";
export default {
  data() {
    return {
      showMiddleBlock:true,
      showBackToTopButton:false,
      size: {
        fonsize: "1.3rem",
        hide: true,
      },
      saveHeaderOperateInfo: {
        // 这个对象储存header和字体消失隐藏的相关逻辑变量
        scrollTopInstance: [0], //初始时候这个标题栏的位置,每一次滑动都会将滑动值赋值给他,用数组是想让这个检测机制检测到重新加载dom,浪费性能
        lastStatus: "equls", //记录上一次的状态是大于还是小于,默认是大于上一次
        topNav: "topNav",
        showOrHidden: "clasTwo",
        textShow: "showText",
        timeOut: true, //节流,true能执行
      },
      // 这里到时候从后台取回来数据渲染,返回数据的时候需要注意,这个算上所有的这个li全部只能够有8个数据
      classifyList: [],
      friendList: [],
      labelList: [],
      websiteInfo: [
        {
          name: "访问次数",
          count: 23,
        },
        {
          name: "文章数量",
          count: 23,
        },
        {
          name: "最后更新时间",
          count: 2023 - 7 - 4,
        },
        {
          name: "本站运行时长",
          count: "9823h",
        },
      ],
      otherInfoObj: {},
    };
  },
  created() {
    this.$bus.$on("showText", this.showText);
  },
  mounted() {
    this.$bus.$on('showMiddleBlock',this.showMiddleBlockMethods)
    this.$bus.$on('showBack',this.showBack);
    getPartClassifyList().then((res) => {
      console.log(res);
      if (res.code != 200) {
        this.$message.error(res.message);
        return;
      }
      this.classifyList = res.data.totalList;
    });
    getAllLabelList().then((res) => {
      console.log(res);
      if (res.code != 200) {
        this.$message.error(res.message);
        return;
      }
      this.labelList = res.data;
    });
    getHeaderList().then(
      (res) => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.$bus.$emit("startDrawingTitle", res.data);
      },
      (err) => {
        this.$message.err(err);
      }
    );
    getModifyInfoList().then(
      (res) => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.otherInfoObj = res.data[0];
        this.websiteInfo[0].count = res.data[0].access_count;
        this.websiteInfo[1].count = res.data[0].allartical_count;
        this.websiteInfo[2].count = res.data[0].last_updatetime;
      },
      (err) => {}
    );
    countOfAccess().then(
      (res) => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
      },
      (err) => {}
    );
    this.getTimeForThisTourSrart();
  },
  methods: {
    showMiddleBlockMethods(info){
      this.showMiddleBlock=info
    },
    chooseThisClassify(event,info){
      viewArtical(info.id).then(res=>{
        if(res.code!=200){
          this.$message.error(res.message)
          return;
        }
        this.$store.commit('changeArticalInRightnowClickClassify',res.data)
        this.$router.push({path:`/showArticalListInfo/${info.classify_name}`})
      },err=>{})
    },
    showBack(){
      this.showBackToTopButton=false
    },
    jump(index) {
      switch (index) {
        case 1:
          this.$router.push("/classifyList");
          break;
        case 2:
          this.$router.push("/labelList");
          break;
        case 3:
          this.$router.push("/friendList");
          break;
      }
    },
    getTimeForThisTourSrart() {
      let nowTime = Date.parse(new Date()); // 获取当前时间戳
      let submitTime = Date.parse("2023-11-2 12:00:00"); // 获取指定时间的时间戳
      let time = (nowTime - submitTime) / 1000; // 获取二者之间的时间差并转换为秒
      let startTime = Math.floor(time / 60 / 60 / 24);
      this.websiteInfo[3].count = startTime + "天";
    },
    addAnimation(e) {
      // 点击回到最上层的逻辑
      if(e.target.scrollTop>700){
        this.showBackToTopButton=true
      }else if(e.target.scrollTop<700){
        this.showBackToTopButton=false
      }
      // 滑动动画效果
      if (!this.saveHeaderOperateInfo.timeOut) {
        return;
      }
      if (
        e.target.scrollTop > this.saveHeaderOperateInfo.scrollTopInstance[0]
      ) {
        this.saveHeaderOperateInfo.scrollTopInstance[0] = e.target.scrollTop;
        if (this.saveHeaderOperateInfo.lastStatus == "greaterThanLastTime") {
          //大于才会进入这里,上一次是大于所以这里直接return
          return;
        }
        if (this.saveHeaderOperateInfo.lastStatus == "equls") {
          this.saveHeaderOperateInfo.lastStatus = "greaterThanLastTime";
          this.saveHeaderOperateInfo.timeOut = false;
          setTimeout(() => {
            this.saveHeaderOperateInfo.timeOut = true;
          }, 200);
          this.saveHeaderOperateInfo.showOrHidden = "clasOne"; //同时这个代替标题栏的高度栏展开代替消失的高度
          this.saveHeaderOperateInfo.topNav = "topNavBck"; //隐藏这个标题栏
          this.$bus.$emit("showText", true); //字体消失
        }
        if (this.saveHeaderOperateInfo.lastStatus == "lessThanLastTime") {
          this.saveHeaderOperateInfo.lastStatus = "greaterThanLastTime";
          this.saveHeaderOperateInfo.timeOut = false;
          setTimeout(() => {
            this.saveHeaderOperateInfo.timeOut = true;
          }, 200);
          this.saveHeaderOperateInfo.showOrHidden = "clasOne"; //同时这个代替标题栏的高度栏展开代替消失的高度
          this.saveHeaderOperateInfo.topNav = "topNavBck"; //隐藏这个标题栏
          this.$bus.$emit("showText", true); //字体消失
        }
      } else {
        this.saveHeaderOperateInfo.scrollTopInstance[0] = e.target.scrollTop;
        if (this.saveHeaderOperateInfo.lastStatus == "lessThanLastTime") {
          //小于才会进入这里,上一次是小于所以这里直接return
          return;
        }
        if (this.saveHeaderOperateInfo.lastStatus == "greaterThanLastTime") {
          this.saveHeaderOperateInfo.lastStatus = "lessThanLastTime"; //将上一次的状态改为小于了
          this.saveHeaderOperateInfo.timeOut = false; //节流一下让动画效果走一走,最少执行200毫秒的动画效果
          setTimeout(() => {
            this.saveHeaderOperateInfo.timeOut = true;
          }, 200);
          this.saveHeaderOperateInfo.showOrHidden = "clasTwo";
          this.saveHeaderOperateInfo.topNav = "topNav";
          this.$bus.$emit("showText", false); //字体显示
        }
      }
    },
    showText(ifHide) {
      if (ifHide) {
        this.saveHeaderOperateInfo.textShow = "hideText";
      } else {
        this.saveHeaderOperateInfo.textShow = "showText";
      }
    },
    scrollToTopMyBlog(){
      document.getElementById("backTpTop").scrollIntoView(true)
    }
  },
};
</script>
<style lang="scss" scpoed>
* {
  margin: 0px;
  padding: 0px;
  font-family: newFamily;
}
.mainContainer {
  width: 100vw;
  height: 100vh;
  background: url("@/assets/homePic/fengjin.jpg");
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow-y: scroll;
  .clasOne {
    display: none;
  }
  .clasTwo {
    height: 4.8rem;
    display: block;
  }
  .topNav {
    position: fixed;
    top: 0rem;
    left: 9.55vw;
    opacity: .6;
    border-radius: 20px;
    animation: hideAnimateBack 0.5s forwards;
  }
  .topNavBck {
    position: fixed;
    left: 9.55vw;
    top: -5rem;
    animation: hideAnimate 0.5s forwards;
  }
}
.main {
  display: flex;
  .main_left {
    width: 10vw;
  }
  .main_middle {
    width: 80vw;
    display: flex;
    flex-direction: column;
    .full_to_say {
      width: 80vw;
      height: 100vh;
      text-align: center;
      .showText {
        line-height: 100vh;
        font-size: 2rem;
        color: white;
        opacity: 1;
        animation: showText 0.5s forwards;
      }
      .hideText {
        line-height: 100vh;
        font-size: 2rem;
        color: white;
        opacity: 1;
        animation: hideText 0.2s forwards;
      }
    }
    .full_to_choose {
      width: 80vw;
      display: flex;

      .middle_left {
        width: 15vw;
        display: flex;
        flex-direction: column;
        // 个人信息展示模块css
        .selfIntroduce {
          overflow: hidden;
          opacity: 0.8;
          margin-top: 1.2rem;
          width: 100%;
          height: 24rem;
          background-color: #feeeed;
          border-radius: 0.4rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .self_one {
            display: flex;
            flex-direction: column;
            .self_one_item_one {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 0.5rem;
              img {
                width: 7rem;
                height: 7rem;
                border-radius: 3.5rem;
                // background-size: 100% 100%;
              }
            }
            .self_one_item_two {
              width: 15vw;
              margin-top: 0.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              div {
                line-height: 1.8rem;
                font-size: 1.5rem;
                text-align: center;
              }
            }
            .self_one_item_three {
              width: 15vw;
              margin-top: 0.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              div {
                text-align: center;
              }
            }
            .self_one_item_four {
              width: 15vw;
              margin-top: 0.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              div {
                text-align: center;
              }
            }
          }
          .self_two {
            display: flex;
            margin-top: 1.2rem;
            width: 15vw;
            .self_two_item_one {
              width: 33.33%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }
          }
          .self_three {
            display: flex;
            margin-top: 1.2rem;
            button {
              border: 0;
              width: 13vw;
              height: 2rem;
              border-radius: 0.8rem;
              background-color: #afb4db;
              font-size: 1.3rem;
            }
          }
          .self_four {
            display: flex;
            margin-top: 0.6rem;
            width: 15vw;
            .public {
              width: 3.75vw;
              height: 1.8rem;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              a {
                font-size: 1.2rem;
                color: #afb4db;
                text-decoration: none;
              }
              a:hover {
                transform: scale(1.2);
              }
            }
          }
        }
        // 分类展示模块css
        .classifyList {
          overflow: hidden;
          opacity: 0.8;
          margin-top: 1.2rem;
          width: 15vw;
          height: 24rem;
          background-color: #feeeed;
          border-radius: 0.4rem;
          .classify_top {
            border-bottom: 1px solid #afb4db;
            height: 3rem;
            width: 15vw;
            display: flex;
            div {
              display: flex;
            }
            .more {
              cursor: pointer;
            }
            .more:hover {
              color: blue;
            }
          }
          .classify_bottom {
            height: 21rem;
            overflow: hidden;
            .classOneTier {
              list-style: none;
              padding-left: 1rem;
              margin-left: 1rem;
              margin-top: 1rem;
              font-size: 1.1rem;
              .classOneTierText {
                height: 1.8rem;
                .oneTierShowBox {
                  border-radius: 0.3rem;
                  width: 12vw;
                  display: flex;
                  .childrenOnTier:nth-child(1) {
                    line-height: 1.8rem;
                    padding-left: 0.4rem;
                    flex: 1;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                  .childrenOnTier:nth-child(2) {
                    line-height: 1.8rem;
                    flex: 1;
                    text-align: right;
                    padding-right: 0.4rem;
                  }
                }
                .oneTierShowBox:hover {
                  background-color: #afb4db;
                  cursor: pointer;
                }
              }
              .classTwoTier {
                list-style: none;
                border-left: 1px solid grey;
                padding-left: 1rem;
                margin-left: 1rem;
                font-size: 1.1rem;
                .classTwoTierText {
                  height: 1.8rem;
                  .twoTierShowBox {
                    height: 1.8rem;
                    border-radius: 0.3rem;
                    width: 10.25vw;
                    display: flex;
                    .childrenOnTier:nth-child(1) {
                      line-height: 1.8rem;
                      padding-left: 0.4rem;
                      flex: 1;
                    }
                    .childrenOnTier:nth-child(2) {
                      line-height: 1.8rem;
                      padding-right: 0.4rem;
                      flex: 1;
                      text-align: right;
                    }
                  }
                  .twoTierShowBox:hover {
                    background-color: #afb4db;
                    cursor: pointer;
                  }
                }
              }
            }
            .labelList {
              margin-left: 1rem;
              .list_item {
                display: inline-block;
                border: 2px solid #afb4db;
                margin: 0.3rem;
                border-radius: 0.2rem;
                a {
                  text-decoration: none;
                  color: black;
                }
              }
            }
          }
        }
        .label {
          opacity: 0.8;
          margin-top: 1.2rem;
          width: 100%;
          height: 24rem;
          background-color: #feeeed;
          border-radius: 0.4rem;
        }
        .friendLink {
          opacity: 0.8;
          margin-top: 1.2rem;
          width: 100%;
          height: 24rem;
          background-color: #feeeed;
          border-radius: 0.4rem;
        }
      }
      .middle_right {
        width: 15vw;
        .commentList {
          overflow: hidden;
          white-space: nowrap;
          opacity: 0.8;
          margin-top: 1.2rem;
          width: 15vw;
          background-color: #feeeed;
          border-radius: 0.4rem;
          .classify_top {
            border-bottom: 1px solid #afb4db;
            height: 3rem;
            width: 15vw;
            display: flex;
            div {
              display: flex;
            }
            .more {
              cursor: pointer;
            }
            .more:hover {
              color: blue;
            }
          }
          .classify_bottom {
            .classOneTier {
              list-style: none;
              padding-left: 1rem;
              margin-left: 1rem;
              margin-top: 1rem;
              font-size: 1.1rem;
              .classOneTierText {
                height: 1.8rem;
                .oneTierShowBox {
                  border-radius: 0.3rem;
                  width: 12vw;
                  display: flex;
                  .childrenOnTier:nth-child(1) {
                    line-height: 1.8rem;
                    padding-left: 0.4rem;
                    flex: 1;
                  }
                  .childrenOnTier:nth-child(2) {
                    line-height: 1.8rem;
                    flex: 1;
                    text-align: right;
                    padding-right: 0.4rem;
                  }
                }
                .oneTierShowBox:hover {
                  background-color: #afb4db;
                  cursor: pointer;
                }
              }

              .classTwoTier {
                list-style: none;
                border-left: 1px solid grey;
                padding-left: 1rem;
                margin-left: 1rem;
                font-size: 1.1rem;
                .classTwoTierText {
                  height: 1.8rem;
                  .twoTierShowBox {
                    height: 1.8rem;
                    border-radius: 0.3rem;
                    width: 10.25vw;
                    display: flex;
                    .childrenOnTier:nth-child(1) {
                      line-height: 1.8rem;
                      padding-left: 0.4rem;
                      flex: 1;
                    }
                    .childrenOnTier:nth-child(2) {
                      line-height: 1.8rem;
                      padding-right: 0.4rem;
                      flex: 1;
                      text-align: right;
                    }
                  }
                  .twoTierShowBox:hover {
                    background-color: #afb4db;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .main_right {
    width: 10vw;
  }
}

@keyframes hideAnimate {
  from {
    top: 0rem;
  }
  to {
    top: -5rem;
  }
}
@keyframes hideAnimateBack {
  from {
    top: -5rem;
  }
  to {
    top: 0rem;
    background-color: rgb(104,130,167);
  }
}
@keyframes showText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes hideText {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.scrollToTop{
  width: 50px;
  height: 50px;
  background-color: #fedacc;
  position: fixed;
  right:2rem;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 15px;
  .icon-shangchuan{
    width: 20px;
    height: 20px;
  }
}
</style>
