import request from '../../index'
export const addLabel=(data)=>{
    return request.post('/label/addlabel',data)
}
export const deleteLabel=(data)=>{
    return request.get(`/label/deletelabel?id=${data}`)
}
export const updateLabel=(data)=>{
    return request.post('/label/modifylabel',data)
}
export const searchLabel=(data)=>{
    return request.get(`/label/searchlabel?label_name=${data}`)
}
export const getAllLabelList=()=>{
    return request.get('/label/getAlllabellist')
}


