<!-- 首页展示页面 -->
<template>
  <div class="middleContainer">
    <div id="backToMe"></div>
    <!-- <v-md-editor v-model="text" :mode="preview"></v-md-editor>-->
    <div class="itemList" v-for="(item,index) in articalList" :key="item">
      <div class="item">
        <div class="item_title" @click="comeToSeeThisArtical(item,index)">{{item.artical_title}}</div>
        <div class="item_message">
          <span class="iconfont icon-rili">{{item.otherInfo.public_time}}/</span>
          <span class="iconfont icon-chakan">{{item.otherInfo.view_number}}/</span>
          <span class="iconfont icon-duihua">{{item.otherInfo.comment_number}}/</span>
          <span class="iconfont icon-dianzan">{{item.otherInfo.like_count}}</span>
        </div>
        <div class="item_introduce">
          <div>
            <!-- 网站最底部内容,最上方的显示隐藏方式需要更改,右侧侧边栏滚动条需要修改,左右两个指导栏,文章页面,md文档展示,点赞,评论,主体黑白切换,返回顶部,分享生活日常 -->
            {{item.introduce}}
          </div>
        </div>
      </div>
    </div>
    <pageNation :totel='total' :currentPage='currentPage' v-if="hideChildren"/>
  </div>
</template>
<script>
import { getArticalListInfo } from "@/api/manage/artical";
export default {
  data() {
    return {
      articalList: [],
      currentPage:'',
      total:'',
      text:
        "## Install\n```bash\n# use npm\nnpm i @kangc/v-md-editor -S\n#use yarn \nyarn add @kangc/v-md-editor\n```\n## Quick Start",
      dameng: "",
      hideChildren:false
    };
  },
  beforeRouteLeave(to,from,next){
    this.$bus.$emit('showMiddleBlock',false)
    next()
  },
  mounted() {
    getArticalListInfo({"currentPage":1,"pageSize":10}).then(res => {
      if (res.code != 200) {
        this.$message.error(res.message);
        return;
      }
      if(res.data.length==0){
        this.$message({
          message:res.message,
          type:'success'
        });
        return
      }
      res.data.arr.forEach(item => {
        item.otherInfo.public_time = item.otherInfo.public_time.replace(
          "T",
          "-"
        );
        item.otherInfo.public_time = item.otherInfo.public_time.replaceAll(
          ".000Z",
          ""
        );
      });
      this.articalList = res.data.arr;
      
      this.$store.commit('getAllArticalListInfo',res.data.arr)
      this.total=Math.ceil(res.data.total/10);
      this.currentPage=res.data.currentPage
      this.hideChildren=true
    });
    this.$bus.$on('getListInfo',this.getListInfo)
  },
  beforeDestroy(){
    this.$bus.$off('getListInfo')
  },
  methods:{
    getListInfo(infoList){
      for(let i=0;i<infoList.length;i++){
        infoList[i].otherInfo.public_time = infoList[i].otherInfo.public_time.replace(
          "T",
          "-"
          );
        infoList[i].otherInfo.public_time = infoList[i].otherInfo.public_time.replaceAll(
          ".000Z",
          ""
        );
      }
      this.articalList=infoList
    },
    comeToSeeThisArtical(item,index){
      this.$router.push({path:`/showarticalinfo/${index}`})
    }
  }
};
</script>
<style lang="scss" scoped>
.middleContainer {
  width: 50vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .itemList {
    opacity: 0.8;
    width: 46vw;
    height: 12rem;
    background-color: #feeeed;
    border-radius: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.2rem;
    overflow: hidden;
    .item {
      width: 96%;
      height: 90%;
      display: flex;
      flex-direction: column;
      .item_title {
        cursor: pointer;
        font-size: 1.8rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
      }
      .item_message {
        font-size: 0.6rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #afb4db;
      }
      .item_introduce {
        margin-top: 0.7rem;
        font-size: 1.1rem;
      }
    }
  }
}
</style>
