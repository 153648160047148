<template>
  <div>
    <ul
      class="classTwoTier" v-for="item in childrenList" :key="item">
      <li
        class="classTwoTierText"
        @click.stop="chooseThisClassify($event, item)"
      >
        <div class="twoTierShowBox">
          <div class="childrenOnTier">{{ item.classify_name }}</div>
          <div class="childrenOnTier">{{ item.count }}</div>
        </div>
      </li>
      <Classify :childrenList="item.children" :homeToView="homeToView" />
    </ul>
  </div>
</template>
<script>
import { viewArtical } from "@/api/manage/artical/index";
export default {
  props: ["childrenList", "homeToView"],
  methods: {
    chooseThisClassify(event, thisCheckInfo) {
      //这个方法是在将这个点击之后的颜色置空
      if (!this.homeToView) {
        let length = document.getElementsByClassName("classTwoTierText").length;
        let length1 =
          document.getElementsByClassName("classOneTierText").length;
        for (let i = 0; i < length; i++) {
          document.getElementsByClassName("classTwoTierText")[
            i
          ].style.backgroundColor = "#ffffff";
        }
        for (let i = 0; i < length1; i++) {
          document.getElementsByClassName("classOneTierText")[
            i
          ].style.backgroundColor = "#ffffff";
        }
        event.target.parentNode.parentNode.style.backgroundColor = "#afb4db";
        this.$bus.$emit("getRightnowCheckInfo", thisCheckInfo, true);
      }else{
        console.log('这里是homeToView',thisCheckInfo);
        viewArtical(thisCheckInfo.id).then((res)=>{
          if(res.code!=200){
            this.$message.error(res.message)
            return
          }
          this.$store.commit('changeArticalInRightnowClickClassify',res.data)
          this.$router.push({path:`/showArticalListInfo/${thisCheckInfo.classify_name}`})
        },(err)=>{})
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.classTwoTier {
  list-style: none;
  border-left: 1px solid grey;
  padding-left: 1rem;
  margin-left: 1rem;
  font-size: 1.1rem;
  .classTwoTierText {
    height: 1.8rem;
    .twoTierShowBox {
      height: 1.8rem;
      border-radius: 0.3rem;
      display: flex;
      .childrenOnTier:nth-child(1) {
        line-height: 1.8rem;
        padding-left: 0.4rem;
        flex: 1;
      }
      .childrenOnTier:nth-child(2) {
        line-height: 1.8rem;
        padding-right: 0.4rem;
        flex: 1;
        text-align: right;
      }
    }
    .twoTierShowBox:hover {
      background-color: #afb4db;
      cursor: pointer;
    }
  }
}
</style>