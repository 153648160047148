<!-- 显示文章相信信息页面 -->
<template>
  <div class="middleContainer">
    <div id="backToMeForArtical"></div>
    <v-md-editor v-model="text" :mode="preview"></v-md-editor>
    <div class="bottom_container">
      <div>
        <span
          class="iconfont icon-fenlei"
          style="margin-left:2rem;margin-bottom:1rem:display:inline-block"
        >
          分类:
          <a style="margin-left: 0.7rem">{{ classify_name }}</a>
        </span>
        <span
          class="iconfont icon-24gf-tags4"
          style="margin-left:2rem;margin-bottom:1rem:display:inline-block"
          >标签:</span
        >
        <el-tag
          :type="item.label_color"
          v-for="item in labelListInfo"
          style="margin-left: 1rem"
          :key="item">{{ item.label_name }}</el-tag
        >
      </div>
      <div class="bottom_comment_container" id="commentAnchorPoint">
        <span :class="DTClass" :style="DTStyle" @click="like()">
          <span style="color: black">{{
            rightNowCheckArticalInfo.otherInfo.like_count
          }}</span>
        </span>
        <span class="iconfont icon-liuyan cur" @click="fold()">
          <span>{{ rightNowCheckArticalInfo.otherInfo.comment_number }}</span>
        </span>
        <span class="iconfont icon-chakan">
          <span>{{ rightNowCheckArticalInfo.otherInfo.view_number }}</span>
        </span>
        <span>
          <span>{{ rightNowCheckArticalInfo.otherInfo.public_time }}</span>
        </span>
      </div>
      <div style="margin-top: 2rem"></div>
      <CommentForArtical
        :articalInfo="rightNowCheckArticalInfo"
        v-if="startDrawChildren"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import {
  selectArticalLabelRelation,
  getAllArticalListInfo,
} from "@/api/manage/artical/index";
import { getClassifyByArticalId } from "@/api/reception/artical/index";
import { selectLike, addLike, cancelLike } from "@/api/reception/like/index";
export default {
  data() {
    return {
      rightNowCheckArticalInfo: {
        artical_text: "",
        artical_title: "",
        id: "",
        introduce: "",
        otherInfo: {
          comment_number: 0,
          like_count: 0,
          public_time: 0,
          view_number: 0,
        },
      },
      text: "",
      preview: "preview",
      foldComment: false,
      startDrawChildren: false,
      labelListInfo: [],
      classify_name: "",
      DTClass: "iconfont icon-xihuan cur",
      DTStyle: {
        "margin-left": "2rem",
      },
      likeOrDoNotLike: "no",
    };
  },
  computed: {
    ...mapState({
      allArticalListInfo: (state) => state.allArticalListInfo,
    }),
    ...mapState({
      articalInRightnowClickClassify: (state) =>
        state.articalInRightnowClickClassify,
    }),
  },
  // 解决f5刷新页面出现404问题
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      debugger
      // 拿到传送过来的文章对应的index
      let index = Number(vm.$route.params.index);
      let fromHere = localStorage.getItem("from");
      // 先看看是不是f5了，from没有那么一定就是f5了，这个时候就不确定是从哪里来的了，就需要判断
      if ((from.name == null) || (from.name == undefined)) {
        if (fromHere == null || from == undefined) { //如果fromHere为空那么来自mainContentArea
          vm.rightNowCheckArticalInfo = vm.$store.state.allArticalListInfo[index];
          vm.initArtical();
        } else { //否则来自showArticalListInfo
          vm.handlerListInfo();
        }
        // 走完了这里就需要return了
        return
      }
      if (from.name == "mainContentArea") { //从首页过来的那么直接去vuex里面取值
        vm.rightNowCheckArticalInfo = vm.$store.state.allArticalListInfo[index];
        vm.initArtical();
        // 只要是从这个mainContentArea来的，一律将这个from清空
        if(fromHere!=null||fromHere!=undefined){
          localStorage.removeItem('from')
        }
      } else if (from.name == "showArticalListInfo"||from.name=="articalList") { //从具体分类那里过来的文章需要存储这个from
        localStorage.setItem("from", true);
        vm.handlerListInfo();
      }
    });
  },
  mounted() {
    document.getElementById("backToMeForArtical").scrollIntoView(true);
    this.$bus.$on("jumpToPoint", this.jumpToPoint);
  },
  methods: {
    handlerListInfo() {
      getAllArticalListInfo().then(
        (res) => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          console.log(res,'看看看res');
          let index = Number(this.$route.params.index);
          let indexOne = res.data.findIndex((item) => {
            return index == item.id;
          });
          this.rightNowCheckArticalInfo = res.data[indexOne];
          this.initArtical();
        },
        (err) => {}
      );
    },
    initArtical() {
      selectArticalLabelRelation(this.rightNowCheckArticalInfo.id).then(
        (res) => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.labelListInfo = res.data;
        }
      );
      getClassifyByArticalId(this.rightNowCheckArticalInfo.id).then((res) => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.classify_name = res.data[0].classify_name;
      });
      selectLike(this.rightNowCheckArticalInfo.id).then((res) => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        if (res.data) {
          this.likeOrDoNotLike = "yes";
          this.DTClass = "iconfont icon-xiai cur";
          this.DTStyle = {
            "margin-left": "2rem",
            color: "red",
          };
        }
      });
      // 这里在初始化评论,这个那个评论的高度已经拿到了,需要将其隐藏
      this.startDrawChildren = true;
      // 下面在给这个文章的具体内容赋值
      this.text =
        "# " +
        this.rightNowCheckArticalInfo.artical_title +
        "\n" +
        this.rightNowCheckArticalInfo.artical_text;
    },
    fold() {
      if (!this.foldComment) {
        this.$bus.$emit("showOrHiddenComment", this.foldComment);
        this.foldComment = true;
      } else {
        this.$bus.$emit("showOrHiddenComment", this.foldComment);
        this.foldComment = false;
      }
    },
    jumpToPoint() {
      document.getElementById("commentAnchorPoint").scrollIntoView(true);
      this.$bus.$emit("showBack");
    },
    like() {
      if (this.likeOrDoNotLike == "no") {
        //需要变为喜欢
        addLike(this.rightNowCheckArticalInfo.id).then((res) => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.likeOrDoNotLike = "yes";
          this.DTClass = "iconfont icon-xiai cur";
          this.DTStyle = {
            "margin-left": "2rem",
            color: "red",
          };
          this.rightNowCheckArticalInfo.otherInfo.like_count =
            this.rightNowCheckArticalInfo.otherInfo.like_count + 1;
        });
      } else {
        //需要变为不喜欢
        cancelLike(this.rightNowCheckArticalInfo.id).then((res) => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.likeOrDoNotLike = "no";
          this.DTClass = "iconfont icon-xihuan cur";
          this.DTStyle = {
            "margin-left": "2rem",
          };
          this.rightNowCheckArticalInfo.otherInfo.like_count =
            this.rightNowCheckArticalInfo.otherInfo.like_count - 1;
        });
      }
    },
  },
  beforeDestroy() {
    this.$bus.$off("showOrHiddenComment");
  },
};
</script>
<style lang="scss" scoped>
.middleContainer {
  width: 48vw;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1.2rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .v-md-editor {
    border-radius: 5px 5px 0px 0px;
  }
  .bottom_container {
    height: auto;
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 3rem;
    .bottom_comment_container {
      width: 100%;
      height: 2rem;
      line-height: 2rem;
      span {
        margin-left: 1rem;
      }
      .cur {
        cursor: pointer;
      }
    }
  }
}
</style>
