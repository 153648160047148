<template>
    <div class="footer">
        <div class="footerOne"></div>
        <div class="footerOne_one">
            <div>
                <span>ICP备案号:</span>
                <a href="https://beian.miit.gov.cn/">冀ICP备2023037450号-1</a>
            </div>
        </div>
        <div class="footerOne"></div>
    </div>
</template>
<script>
export default{
    data(){

    }
}
</script>
<style lang="scss" scoped>
.footer{
    height: 3rem;
    display: flex;
    .footerOne{
        flex: 1;
    }
    .footerOne_one{
        color: white;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        div{
            width: 80%;
            height: 80%;
            text-align: center;
            line-height: 2.4rem;
            a{
                color: white;
                text-decoration:none;
            }
        }
    }

}
</style>