<template>
  <div class="container">
    <v-md-editor
      v-model="articalText"
      left-toolbar="undo redo | image emoji"
      height="70vh"
      :disabled-menus="[]"
      @upload-image="handleUploadImage"
    />
    <el-card class="container_bottom">
      <el-row :span="24" style="margin-top:.3rem">
        <el-col :span="2" style="line-height:2.5rem;">文章标题:</el-col>
        <el-col :span="6">
          <el-input v-model="articalTitle"></el-input>
        </el-col>
        <el-col :span="6"></el-col>
        <el-col :span="6"></el-col>
      </el-row>
      <el-row :span="24" style="margin-top:1rem">
        <el-col :span="2" style="line-height:2.5rem;">选择分类:</el-col>
        <el-col :span="3">
          <el-select v-model="chooseClassify" placeholder="请选择">
            <el-option
              v-for="item in classifyList"
              :key="item.classify_name"
              :label="item.classify_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="2" style="line-height:2.5rem;">
          <span style="float: right;margin-right:1rem">添加标签:</span>
        </el-col>
        <el-col :span="3">
          <el-select v-model="chooseTag" placeholder="请选择">
            <el-option
              v-for="item in tagList"
              :key="item.label_name"
              :label="item.label_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="2" style="line-height:2.5rem;">
          <el-button @click="addLabelForArtical()">添加</el-button>
        </el-col>
        <el-col :span="12" style="line-height:2.5rem;">
          <el-tag
            :key="tag.label_name"
            v-for="tag in bindTagListArr"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >{{tag.label_name}}</el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="tagInputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          ></el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
        </el-col>
      </el-row>
      <el-row :span="24" style="margin-top:1rem" v-if="addOrModify">
        <el-col :span="10">&nbsp;</el-col>
        <el-col :span="4">
          <el-button type="success" style="width: 100%;" @click="articalPublic()">发布</el-button>
        </el-col>
        <el-col :span="10">&nbsp;</el-col>
      </el-row>
      <el-row :span="24" style="margin-top:1rem" v-else>
        <el-col :span="9">&nbsp;</el-col>
        <el-col :span="3">
          <el-button type="success" style="width: 90%;" @click="backToSeeArticalList()">返回</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="success" style="width: 90%;" @click="modifyArtical()">修改</el-button>
        </el-col>
        <el-col :span="9">&nbsp;</el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import axios from "axios";
import { getAllClassifyListForSelect } from "@/api/manage/classify";
import { getAllLabelList } from "@/api/manage/label";
import {
  publicArtical,
  selectArticalLabelRelation,
  modifyArticalInfo
} from "@/api/manage/artical";
import { mapState } from "vuex";
export default {
  data() {
    return {
      articalText: "", //文章内容
      articalTitle: "", //文章标题,
      chooseClassify: "",
      chooseTag: "",
      classifyList: [],
      sendTagList: [],
      tagList: [], //从后端传回来的所有标签列表

      //   下面是标签
      bindTagListArr: [],
      inputVisible: false,
      tagInputValue: "",
      addOrModify: true
    };
  },
  computed: {
    ...mapState({
      articalInfo: state => state.articalInfo
    })
  },
  mounted() {
    this.getClassifyList();
    this.getAllTagListForSelect();
    if (this.articalInfo) {
      this.articalTitle = this.articalInfo.artical_title;
      this.articalText = this.articalInfo.artical_text;
      this.chooseClassify = this.articalInfo.artical_classify_id;
      this.addOrModify = false;
      selectArticalLabelRelation(this.articalInfo.id).then(
        res => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          if(res.data!=null||res.data!=undefined){
            this.bindTagListArr = res.data;
          }else{
            this.bindTagListArr=[]
          }
          // 从这里返回的数据直接就替代这个bindTagListArr就好了,然后后端返回回来的东西应该是整理好的,全部都应该是label表里面对应的数据
        },
        err => {}
      );
    }
  },
  methods: {
    modifyArtical() {
      if (this.articalTitle.replace(/\s+/g, "") == "") {
        this.$message({ message: "文章表体不能为空", type: "warning" });
        return;
      }
      if (this.articalText.length == 0) {
        this.$message({ message: "文章内容不能为空", type: "warning" });
        return;
      }
      if (this.chooseClassify == "") {
        this.$message({ message: "请选择分类", type: "warning" });
        return;
      }
      
      let obj = {
        id:this.$store.state.articalInfo.id,
        artical_title: this.articalTitle,
        artical_text: this.articalText,
        artical_classify_id: Number(this.chooseClassify),
        articalBindLabelList: this.bindTagListArr
      };
      console.log(obj,'==================');
      modifyArticalInfo(obj).then(res => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.$message({
            message: res.message,
            type: "success"
          });
        },
        err => {}
      );
    },
    backToSeeArticalList() {
      this.$store.commit("modifyArticalInfo", null);
      this.$router.push("/reviseArticle");
    },
    articalPublic() {
      if (this.articalTitle.replace(/\s+/g, "") == "") {
        this.$message({ message: "文章表体不能为空", type: "warning" });
        return;
      }
      if (this.articalText.length == 0) {
        this.$message({ message: "文章内容不能为空", type: "warning" });
        return;
      }
      if (this.chooseClassify == "") {
        this.$message({ message: "请选择分类", type: "warning" });
        return;
      }
      let obj = {
        artical_title: this.articalTitle,
        artical_text: this.articalText,
        artical_classify_id: Number(this.chooseClassify),
        articalBindLabelList: this.bindTagListArr
      };
      publicArtical(obj).then(res => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.$message({
          message: res.message,
          type: "success"
        });
        this.articalTitle = "";
        this.articalText = "";
        (this.chooseClassify = ""), (this.bindTagListArr = []);
      });
    },
    handleUploadImage(event, insertImage, files) {
      console.log(files[0]);
      let url = ``;
      const fromData = new FormData();
      fromData.append("file", files[0]);
      axios.post("http://43.143.169.111:9999/api/image/imagehandler", fromData, {
          headers: {
            "Content-Type": "multipart/from-data"
          }
        }).then(res => {
          if (res.data.code != 200) {
            this.$message.error(res.data.message);
            return;
          }
          url = res.data.url;
          insertImage({
            url: url,
            desc: files[0].name
            // width: 'auto',
            // height: 'auto',
          });
        });
    },
    getClassifyList() {
      getAllClassifyListForSelect().then(res => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.classifyList = res.data;
      });
    },
    getAllTagListForSelect() {
      getAllLabelList().then(res => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        if(res.data==null||res.data==undefined){
          this.tagList=[]
        }else{
          this.tagList = res.data;
        }
      });
    },
    /**
     * 点击添加按钮添加新的这个标签
     */
    addLabelForArtical() {
      if(this.tagList.length==0){
        return
      }
      if(this.chooseTag==""){
        this.$message({
          message:'请选择需要添加的标签',
          type:'warning'
        })
        return
      }
      let index = this.tagList.findIndex(item => {
        return item.id == this.chooseTag;
      });
      this.bindTagListArr.push(this.tagList[index]);
    },
    /**
     * 删除选中tag
     * @param {*当前点击元素对应的对象} tag
     */
    handleClose(tag) {
      this.bindTagListArr.splice(this.bindTagListArr.indexOf(tag), 1);
    },
    /**
     * 点击添加之后显示input
     */
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    /**
     * 失去焦点或者enter键弹起执行添加标签
     */
    handleInputConfirm() {
      // 在这里发送请求,添加成功之后将这个id返回回来然后push到这个数组里面去就好了
      let tagInputValue = this.tagInputValue;
      if (tagInputValue) {
        let obj = {
          id: null,
          label_name: tagInputValue,
          label_color: ""
        };
        this.bindTagListArr.push(obj);
      }
      this.inputVisible = false;
      this.tagInputValue = "";
      console.log(
        "每次失去焦点都会就会将这个list log出来",
        this.bindTagListArr
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100vh;
  width: calc(97vw - 200px);
  .container_bottom {
    width: 100%;
    height: 22vh;
    margin-top: 1rem;
  }
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>