<!-- 友联链接列表 -->
<template>
  <div class="middleContainer">
    <div class="container">友链列表</div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  }
};
</script>
<style lang="scss" scoped>
.middleContainer {
  width: 50vw;
  .container {
    margin-left: 2vw;
    opacity: 0.8;
    width: 46vw;
    height: auto;
    background-color: #feeeed;
    border-radius: 0.6rem;
    margin-top: 1.2rem;
  }
}
</style>