import Vue from 'vue'
import VueRouter from 'vue-router'
import mainContentArea from '@/views/mainContentArea/index.vue'
import articalList from '@/views/articalList/index.vue'
import shareToWe from '@/views/shareToWe/index.vue'
import home from '@/views/home/index.vue'
import archivemanagement from '@/views/archivemanagement/login/index.vue'
import manageHome from '@/views/archivemanagement/home/index.vue'
import bigData from '@/views/archivemanagement/bigdata/index.vue'
import labelAndClassify from '@/views/archivemanagement/labelandclassify/index.vue'
import selfCenter from '@/views/archivemanagement/selfcenter/index.vue'
import articalManage from '@/views/archivemanagement/articalmanage/index.vue'
import reviseArticle from '@/views/archivemanagement/reviseArticle/index.vue'
import showArticalInfo from '@/views/showarticalInfo'
import classifyList from '@/views/showClassifyList'
import showLabelList from '@/views/showLabelList'
import friendshipList from '@/views/friendshipList'
import showArticalListInfo from "@/views/showArticalListInfo/index.vue"
Vue.use(VueRouter)

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}
const routes = [{
        path: '/',
        name: 'home',
        component: home,
        children: [{
                path: '', //首页
                redirect: 'mainContentArea' //这个重定向的方法还是很好用的
            },
            {
                path: '/mainContentArea',
                name: 'mainContentArea',
                component: mainContentArea
            },
            {
                path: '/articalList', //文章列表页面
                name: 'articalList',
                component: articalList
            },
            {
                path: '/share', //说说页面
                name: 'share',
                component: shareToWe
            },
            {
                path: '/showarticalinfo/:index', //文章详情页面
                name: 'showarticalinfo',
                component: showArticalInfo
            },
            {
                path: '/classifyList',
                name: 'classifyList',
                component: classifyList
            },
            {
                path: '/labelList',
                name: 'labelList',
                component: showLabelList
            },
            {
                path: '/friendList',
                name: 'friendList',
                component: friendshipList
            },
            {
                path: "/showArticalListInfo/:name",
                name: 'showArticalListInfo',
                component: showArticalListInfo
            }
        ]
    },
    {
        path: '/archivemanagement',
        name: 'archivemanagement',
        component: archivemanagement
    },
    {
        path: '/manage_home',
        name: 'manage_home',
        component: manageHome,
        children: [{
                path: '/',
                name: 'bigdata',
                component: bigData
            },
            {
                path: '/labelandclassify',
                name: 'labelandclassify',
                component: labelAndClassify
            },
            {
                path: '/articalmanage',
                name: 'articalmanage',
                component: articalManage
            },
            {
                path: '/selfcenter',
                name: 'selfcenter',
                component: selfCenter
            },
            {
                path: '/reviseArticle',
                name: 'reviseArticle',
                component: reviseArticle
            },
            {
                path: '/talkToUs',
                name: 'talkToUs',
                component: ()=>import('@/views/archivemanagement/shareToUs/index.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
