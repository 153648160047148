import request from "@/api/index";
export const publicArtical = (data) => {
  return request.post("/artical/articalpublic", data);
};
export const viewArtical = (id) => {
  return request.get(`/artical/viewartical/${id}`);
};
export const modifyArticalInfo = (data) => {
  return request.post("artical/modifyartical", data);
};
export const selectArticalLabelRelation = (id) => {
  return request.get(`/artical/getarticallabelrelation/${id}`);
};
export const getArticalListInfo = (data) => {
  return request.post("/artical/getArticalListInfo", data);
};
export const getAllArticalListInfo = () => {
  return request.get("/artical/getAllArticalListInfo");
};
