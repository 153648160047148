import request from '@/api/index'
export const getHeaderList=()=>{
    return request.get('/info/getheaderlist')
}
export const modifyHeaderList=(data)=>{
    return request.post('/info/modifyheaderlist',data)
}
export const modifyInfoList=(data)=>{
    return request.post('/info/modifyInfoList',data)
}
export const getModifyInfoList=()=>{
    return request.get('/info/getModifyInfoList',)
}