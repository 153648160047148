<template>
  <div class="card_container">
    <el-card class="classifyList" v-if="showArtical">
      <h3>请选择分类可查看其分类下所有文章并进行操作:</h3>
      <el-row id="classify_father">
        <div class="classify_bottom" id="classify_children">
          <ul class="classOneTier" v-for="item in classifyList" :key="item">
            <li class="classOneTierText" @click.stop="chooseThisClassify($event, item)">
              <div class="oneTierShowBox">
                <div class="childrenOnTier">{{ item.classify_name }}</div>
                <div class="childrenOnTier">{{ item.count }}</div>
              </div>
            </li>
            <Classify :childrenList="item.children" :homeToView="false"/>
          </ul>
        </div>
      </el-row>
    </el-card>
    <el-card class="articalList" v-if="showArtical">
      <el-row
        :span="24"
        v-for="item in articalListInThisClassify"
        :key="item.artical_title"
      >
        <el-col :span="15"
          ><span style="margin-left: 2rem; line-height: 2.5rem">{{
            item.artical_title
          }}</span></el-col
        >
        <el-col :span="3"
          ><el-button @click="handlerArtical(1, item)">查看</el-button></el-col
        >
        <el-col :span="3"
          ><el-button @click="handlerArtical(2, item)">修改</el-button></el-col
        >
        <el-col :span="3"
          ><el-button @click="handlerArtical(3, item)">删除</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card v-else class="displayArtical" style="background-color: #fffafa">
      <v-md-editor v-model="text" height="700px" :mode="preview"></v-md-editor>
      <el-row :span="24" style="margin-top: 5rem">
        <el-col :span="10">&nbsp;</el-col>
        <el-col :span="4"
          ><el-button @click="backToChoose()">返回</el-button></el-col
        >
        <el-col :span="10"> &nbsp;</el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { getallclassifylistOne } from "@/api/manage/classify/index";
import { viewArtical } from "@/api/manage/artical/index";
export default {
  name: "reviseArticle",
  data() {
    return {
      classifyList: [],
      rightnowCheckedInfo: null,
      articalListInThisClassify: [],
      text: `# 标题 \n即可立即送i新街口心理健康老师看了撒`,
      showArtical: true,
      preview: "preview",
      rightnowCheckedTextInfo: null,
    };
  },
  mounted() {
    this.getAllClassifyList();
    this.$bus.$on("getRightnowCheckInfo", this.getRightnowCheckInfo);
  },
  beforeDestroy(){
    this.$bus.$off('getRightnowCheckInfo')
  },
  methods: {
    backToChoose() {
      this.view = true;
      this.showArtical = true;
      this.preview = "preview";
    },
    /**
     * 置空别的元素背景色,设置当前元素的背景色
     * @param {*} event 默认事件
     * @param {*} thisCheckInfo 当前选中元素对应的信息
     */
    chooseThisClassify(event, thisCheckInfo) {
      console.log(thisCheckInfo, "----------------");
      let length = document.getElementsByClassName("classTwoTierText").length;
      let length1 = document.getElementsByClassName("classOneTierText").length;
      for (let i = 0; i < length; i++) {
        document.getElementsByClassName("classTwoTierText")[
          i
        ].style.backgroundColor = "#ffffff";
      }
      for (let i = 0; i < length1; i++) {
        document.getElementsByClassName("classOneTierText")[
          i
        ].style.backgroundColor = "#ffffff";
      }
      event.target.parentNode.parentNode.style.backgroundColor = "#afb4db";
      this.rightnowCheckedInfo = thisCheckInfo;
      viewArtical(thisCheckInfo.id).then(
        (res) => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.$message({
            message: res.message,
            type: "success",
          });
          this.articalListInThisClassify = res.data;
        },
        (err) => {}
      );
    },
    /**
     * 获取所有的分类信息进行渲染
     */
    getAllClassifyList() {
      getallclassifylistOne().then(
        (resOne) => {
          if (resOne.code != 200) {
            this.$message.error(resOne.message);
            return;
          }
          this.classifyList = resOne.data.totalList;
        },
        (errOne) => {}
      );
    },
    getRightnowCheckInfo(info) {
      this.rightnowCheckedInfo = info;
      viewArtical(info.id).then(
        (res) => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.$message({
            message: res.message,
            type: "success",
          });
          this.articalListInThisClassify = res.data;
        },
        (err) => {}
      );
    },
    handlerArtical(status, chooseItem) {
      switch (status) {
        case 1: //这里是查看.下面这一段查看逻辑就已经写完了
          this.showArtical = false;
          this.text = chooseItem.artical_text;
          this.rightnowCheckedTextInfo = chooseItem; //记录当前查看文章的信息
          break;
        case 2: //这里是修改
          this.$store.commit('modifyArticalInfo',chooseItem)
          this.$router.push("/articalmanage");
          break;
        case 3: //这里是删除
          
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#classify_father {
  margin-top: 2rem;
  overflow: scroll;
}
/* 整个滚动条 */
#classify_father::-webkit-scrollbar {
  width: 0px;
  background-color: #ffffff;
}

/* 滚动条上的滚动滑块 */
#classify_father::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}
.card_container {
  width: 100%;
  height: 96vh;
  display: flex;
  .classifyList {
    width: 50%;
    .classify_bottom {
      height: auto;
      .classOneTier {
        list-style: none;
        padding-left: 1rem;
        margin-left: 1rem;
        margin-top: 1rem;
        font-size: 1.1rem;
        .classOneTierText {
          height: 1.8rem;
          .oneTierShowBox {
            border-radius: 0.3rem;
            display: flex;
            .childrenOnTier:nth-child(1) {
              line-height: 1.8rem;
              padding-left: 0.4rem;
              flex: 1;
            }
            .childrenOnTier:nth-child(2) {
              line-height: 1.8rem;
              flex: 1;
              text-align: right;
              padding-right: 0.4rem;
            }
          }
          .oneTierShowBox:hover {
            background-color: #afb4db;
            cursor: pointer;
          }
        }
      }
      .labelList {
        margin-left: 1rem;
        overflow: hidden;
        .list_item {
          display: inline-block;
          border: 2px solid #afb4db;
          margin: 0.3rem;
          border-radius: 0.2rem;
          a {
            text-decoration: none;
            color: black;
          }
        }
      }
    }
  }
  .articalList {
    width: 50%;
    overflow-y:scroll ;
  }
  .displayArtical {
    width: 100%;
    height: 95vh;
  }
}
</style>
