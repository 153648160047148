<template>
  <div>
    <div class="header" @click="letInputBack()">
      <div class="saveText">
        <!-- 头像 -->
        <div class="sculpture" v-if="ifhiddenStrikersee">
          <div class="sculpture_TX">
            <img src="../../assets/logo.png" />
          </div>
          <div class="sculpture_EN" :style="font_Size">
            <span>striker</span>
          </div>
        </div>
        <div class="listAndSearch">
          <div class="list" :style="styleFlex">
            <ul class="header_navigation">
              <li :class="item.iconfont" :style="font_Size" v-for="item in pageTitleList" :key="item.label">
                <span @click="jumpPage(item.key)">{{item.label}}</span>
              </li>
            </ul>
          </div>
          <!-- 下面只要是v-if为false那么在后台就可展示 -->
          <div class="search">
            <div style="flex: 1"></div>
            <div style="flex: 1">
              <div class="searchInput" @click.stop="changeInput()">
                <span class="iconfont icon-sousuo"></span>
                <input type="text" placeholder="请输入内容" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["size"],
  data() {
    return {
      inputFocus: false,
      font_Size: {
        fontSize: "1rem"
      },
      showSearch: true,
      ifhiddenStrikersee: true,
      styleFlex: { flex: 1 },
      pageTitleList: []
    };
  },
  mounted() {
    console.log(this.size,'----------------');
    this.$bus.$on("startDrawingTitle", this.startDrawingTitle);
    this.font_Size = {
      fontSize: this.size.fontsize
    };
    this.ifhiddenStrikersee = this.size.hide;
    if (!this.ifhiddenStrikersee) {
      this.styleFlex = { flex: 10 };
    }
  },
  methods: {
    startDrawingTitle(list) {
      console.log(list,'-------');
      this.pageTitleList = list;
    },
    changeInput() {
      if (!this.inputFocus) {
        let inputFather = document.getElementsByClassName("searchInput")[0];
        inputFather.style.left = "4rem";
        inputFather.style.backgroundColor = "#1b2228";
        this.inputFocus = true;
      } else {
        // 这里需要发送接口了
      }
    },
    letInputBack() {
      if (this.inputFocus) {
        //表示他已经被选中了,点击就会执行取消选中的操作
        let inputFather = document.getElementsByClassName("searchInput")[0];
        inputFather.style.left = "18.5rem";
        inputFather.style.removeProperty("background-color");
        this.inputFocus = false;
      }
    },
    jumpPage(num) {
      switch (num) {
        case 1:
          this.$bus.$emit('showMiddleBlock',true)
          this.$router.push("/");
          break;
        case 2:
          this.$router.push("/articalList");
          break;
        case 3:
          this.$router.push("/share");
          break;
        case 4:
          this.$router.push("/talk")
          break;
        case 5:
          this.$router.push("/about")
          break;
        case 6:
          this.$router.push("/leavemessage")
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
* {
  font-family: newFamily;
}
.header {
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .saveText {
    width: 80vw;
    height: 4.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .sculpture {
      cursor: pointer;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .sculpture_TX {
        flex: 1;
        img {
          width: 1.8rem;
          height: 1.8rem;
        }
      }
      .sculpture_EN {
        flex: 2;
        display: flex;
        align-items: center;
        span {
          width: 100%;
          text-align: center;
          font-family: newFamily;
          font-size: 1.8rem;
          font-weight: 600;
          color: white;
        }
      }
    }
    .listAndSearch {
      flex: 10;
      display: flex;
      align-items: center;
      .list {
        flex: 1;
        .header_navigation {
          list-style: none;
          display: flex;
          align-items: center;
          li {
            cursor: pointer;
            color: white;
            font-size: 1.25rem;
            padding-left: 2rem;
            span {
              padding-left: 0.5rem;
              font-weight: 700;
            }
          }
        }
      }
      .search {
        flex: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
        .searchInput {
          cursor: pointer;
          width: 80%;
          display: flex;
          align-items: center;
          transition: all 0.2s linear;
          position: relative;
          left: 18.5rem;
          span {
            flex: 2;
            font-size: 1.6rem;
            color: white;
            font-weight: 600;
          }
          input {
            flex: 9;
            outline: none;
            height: 2rem;
            margin-left: 0.3rem;
            background-color: #1b2228;
            border: 0px;
            color: white;
            font-size: 1.3rem;
          }
          input:focus {
            caret-color: white;
          }
          input::placeholder {
            color: white;
          }
        }
      }
    }
  }
}
</style>
