import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Header from '@/components/Header'
import Comment from '@/components/comment'
import pageNation from '@/components/pagenation'
import CommentForArtical from '@/components/commentForArtical'
import './assets/icon/iconfont.css';
import './assets/style/font-family.css'
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import classify from './components/classify'
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'; 
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';
import footer from '@/components/footer'
VueMarkdownEditor.use(createEmojiPlugin());
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});
Vue.use(ElementUI);
Vue.use(VueMarkdownEditor);
Vue.component('Footer',footer)
Vue.component('Header', Header)
Vue.component('Comment', Comment)
Vue.component('Classify', classify)
Vue.component('pageNation',pageNation)
Vue.component('CommentForArtical',CommentForArtical)
Vue.config.productionTip = false
new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this //全局事件总线
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
