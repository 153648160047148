<template>
    <div class="loginContainer">
        <div class="forHover">
            <div class="login">
                <div>
                    Login
                </div>
            </div>
            <div class="name top">
                <input type="text" v-model="loginObj.userName">
            </div>
            <div class="password top"><input type="password" v-model="loginObj.password"></div>
            <div class="go top">
                <div>
                    <button @click="loginToManage()">sign in</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default{
    data(){
        return{
            loginObj:{
                userName:'strikersee',
                password:'xuelinG199822'
            }
        }
    },
    methods:{
        loginToManage(){
            axios.post('http://43.143.169.111:9999/api/user/login',this.loginObj).then(res=>{
                if(res.data.code!=200){
                    alert(res.data.message)
                    return
                }
                this.$router.push('/manage_home')
            },err=>{
                console.log(err,'这里是报错信息');
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.loginContainer{
    width: 100vw;
    height: 100vh;
    background-image: url('@/assets/homePic/longMao.jpg');
    background-size: 100% 100%;
    .forHover{
        width: 20rem;
        height: 30rem;
        background-color: #6b8c90;
        position: absolute;
        top:15rem;
        right: 15rem;
        border-radius: 1rem;
        opacity: .7;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .top{
            margin-top: 2rem;
        }
        .login{
            margin-top: -3rem;
            font-size: 3rem;
            color: white;
            width: 80%;
            height: 3rem;
            display: flex;
            justify-content: center;
        }
        .name{
            width: 80%;
            height: 2rem;
            display: flex;
            justify-content: center;
            input{
                width: 100%;
                border-radius:.3rem;
            }
        }
        .password{
            width: 80%;
            height: 2rem;
            display: flex;
            justify-content: center;
            input{
                width: 100%;
                border-radius:.3rem;
            }
        }
        .go{
            width: 80%;
            height: 2rem;
            display: flex;
            justify-content: center;
            div{
                width: 10rem;
                height: 2.5rem;
                button{
                    font-size: 1.5rem;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

</style>