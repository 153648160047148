<template>
  <div class="container">
    <div class="left">
      <el-card>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt />
        </el-dialog>
        <el-row :span="24">
          <el-col :span="6">&nbsp;首页头像:</el-col>
          <el-col :span="12">
            <el-upload
              action="#"
              list-type="picture-card"
              :auto-upload="false"
              :on-change="handlerChange"
              :limit="1"
              :on-exceed="exceed"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-col>
          <el-col :span="6">&nbsp;</el-col>
        </el-row>
        <el-row :span="24" class="el_row">
          <el-col :span="3">首页标语:</el-col>
          <el-col :span="21">
            <el-input v-model="otherInfoList.page_title"></el-input>
          </el-col>
        </el-row>
        <el-row :span="24" class="el_row">
          <el-col :span="3">昵称:</el-col>
          <el-col :span="21">
            <el-input v-model="otherInfoList.show_user_name"></el-input>
          </el-col>
        </el-row>
        <el-row :span="24" class="el_row">
          <el-col :span="3">座右铭:</el-col>
          <el-col :span="21">
            <el-input v-model="otherInfoList.user_motto"></el-input>
          </el-col>
        </el-row>
        <el-row :span="24" class="el_row">
          <el-col :span="3">地址:</el-col>
          <el-col :span="21">
            <el-input v-model="otherInfoList.user_address"></el-input>
          </el-col>
        </el-row>
        <el-row :span="24" class="el_row el_bottom">
          <el-col :span="10">&nbsp;</el-col>
          <el-col :span="4">
            <el-button @click="modifyHomeOtherInfo()">确认修改</el-button>
          </el-col>
          <el-col :span="10">&nbsp;</el-col>
        </el-row>
      </el-card>
    </div>
    <div class="right">
      <el-card>
        <div style="background-color:#597c9e;height:20%;width:100%">
          <Header :size="size" />
        </div>
        <el-row :span="24">
          <hr />
          <div style="margin-top:2rem;font-size:1.3rem;margin-bottom:2rem">可对已有的首页导航栏进行自由排序和修改:</div>
          <el-transfer v-model="value" :data="dataList"></el-transfer>
        </el-row>
        <el-row :gutter="24" style="margin-top:1rem">
          <el-col :span="8">&nbsp;</el-col>
          <el-col :span="8">
            <el-button @click="confirmToModify()">确认修改</el-button>
          </el-col>
          <el-col :span="8">&nbsp;</el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>
<script>
import {
  getHeaderList,
  modifyHeaderList,
  modifyInfoList,
  getModifyInfoList
} from "@/api/manage/homeotherInfo";
import axios from "axios";
export default {
  data() {
    return {
      imageFile: "",
      otherInfoList: {
        page_title: "",
        show_user_name: "",
        user_motto: "",
        user_address: ""
      },
      // 这个数组始终都是不会变的
      dataList: [
        { key: 1, label: "首页", iconfont: "iconfont icon-shouye" },
        { key: 2, label: "文章", iconfont: "iconfont icon-fabiaowenzhang" },
        { key: 3, label: "说说", iconfont: "iconfont icon-liuyan" },
        { key: 4, label: "友链", iconfont: "iconfont icon-xiai" },
        { key: 5, label: "关于", iconfont: "iconfont icon-shenfenzheng" },
        { key: 6, label: "留言板", iconfont: "iconfont icon-duihua" }
      ],
      copyInfoList: {},
      sendToHeaderForChange: [],
      // 这个数组中存在的key将会放置在列表2中
      value: [],
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      // 传送给header界面用来控制字体大小的对象
      size: {
        fontsize: "1rem",
        hide: false
      }
    };
  },
  mounted() {
    // 在最开始的时候需要将后端返回来的数据发送给header组件让他显示当前状态对应的header
    // 这里需要发送请求,从数据库返回
    getHeaderList().then(
      res => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.$bus.$emit("startDrawingTitle", res.data);
        console.log(res.data, "seesee");
        this.dataList.forEach(item => {
          let index = res.data.findIndex(itemOne => {
            return itemOne.key == item.key;
          });
          if (index == -1) { //如果没有
            this.value.push(item.key);
          }
        });
      },
      err => {
        this.$message.err(err);
      }
    );
    getModifyInfoList().then(
      res => {
        if (res.code != 200) {
          this.$message.error(res.message);
          return;
        }
        this.copyInfoList.page_title = res.data[0].page_title;
        this.copyInfoList.show_user_name = res.data[0].show_user_name;
        this.copyInfoList.user_motto = res.data[0].user_motto;
        this.copyInfoList.user_address = res.data[0].user_address;
        this.otherInfoList.page_title = res.data[0].page_title;
        this.otherInfoList.show_user_name = res.data[0].show_user_name;
        this.otherInfoList.user_motto = res.data[0].user_motto;
        this.otherInfoList.user_address = res.data[0].user_address;
      },
      err => {}
    );
  },
  methods: {
    modifyHomeOtherInfo() {
      if (this.imageFile == "") {
        let flag = {};
        let kjs = true;
        for (const key in this.copyInfoList) {
          if (this.copyInfoList[key] != this.otherInfoList[key]) {
            flag[key] = this.otherInfoList[key];
            kjs = false;
          }
        }
        if (kjs) {
          this.$message({
            message: "并不存在修改",
            type: "warning"
          });
          return;
        }
        modifyInfoList(flag).then(res => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.$message({
            message: res.messgae,
            type: "success"
          });
        });
      } else {
        let fileObj = new FormData();
        fileObj.append("file", this.imageFile.raw);
        axios
          .post("http://43.143.169.111:9999/api/image/saveHomePic", fileObj, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(
            res => {
              if (res.data.code != 200) {
                this.$message.error(res.message);
                return;
              }
              let flag = {};
              let kjs = true;
              for (const key in this.copyInfoList) {
                if (this.copyInfoList[key] != this.otherInfoList[key]) {
                  flag[key] = this.otherInfoList[key];
                  kjs = false;
                }
              }
              if (kjs) {
                return;
              }
              modifyInfoList(this.otherInfoList).then(
                res => {
                  if (res.code != 200) {
                    this.$message.error(res.message);
                    return;
                  }
                  this.$message({
                    message: res.messgae,
                    type: "success"
                  });
                },
                err => {}
              );
            },
            err => {}
          );
      }
    },
    confirmToModify() {
      modifyHeaderList(this.sendToHeaderForChange).then(
        res => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
          this.$message({
            message: res.message,
            type: "success"
          });
        },
        err => {}
      );
    },
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file, "0----------");
    },
    handlerChange(file, fileList) {
      this.imageFile = file;
    },
    exceed() {
      this.$message({
        message: "超出图片可选数量范围,固定只能选中1张",
        type: "warning"
      });
    }
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        this.sendToHeaderForChange = [];
        if (newValue.length > 0) {
          this.dataList.forEach(item => {
            let indexOne = this.value.findIndex(itemOne => {
              return itemOne == item.key;
            });
            if (indexOne == -1) {
              this.sendToHeaderForChange.push(item);
            }
          });
        } else if (newValue == 0) {
          this.sendToHeaderForChange = this.dataList;
        }
        this.$bus.$emit("startDrawingTitle", this.sendToHeaderForChange);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  .left {
    width: 48%;
    height: 100%;
    .el-card {
      height: 100%;
    }
  }
  .right {
    width: 48%;
    height: 100%;
    margin-left: 2rem;
    .el-card {
      height: 100%;
    }
  }
}
.el_row {
  margin-top: 1rem;
}
.el_bottom {
  margin-bottom: 1rem;
}
</style>