import axios from 'axios';
const request = axios.create({
    timeout: 5000,
    baseURL: 'http://43.143.169.111:9999/api'
})
request.interceptors.request.use(res => {
    return res
}, err => {
    return Promise.reject(err);
})
request.interceptors.response.use(res => {
    return res.data
}, err => {
    return Promise.reject(err);
})
export default request;
