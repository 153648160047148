<!-- 点击分类更多之后出现的页面 -->
<template>
  <div class="middleContainer">
    <div id="backToClassify"></div>
    <div class="container">
      <div class="classify_bottom" id="classify_children">
        <ul class="classOneTier" v-for="item in classifyList" :key="item">
          <li class="classOneTierText" @click.stop="chooseThisClassify($event, item)">
            <div class="oneTierShowBox">
              <div class="childrenOnTier">{{ item.classify_name }}</div>
              <div class="childrenOnTier">{{ item.count }}</div>
            </div>
          </li>
          <Classify :childrenList="item.children" :homeToView="true"/>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {getallclassifylistOne} from '@/api/manage/classify'
import { viewArtical } from "@/api/manage/artical/index";
export default {
  data() {
    return {
        classifyList:[]
    };
  },
  mounted() {
    document.getElementById("backToClassify").scrollIntoView(true)
    getallclassifylistOne().then(
        (resOne) => {
          if (resOne.code != 200) {
            this.$message.error(resOne.message);
            return;
          }
          this.classifyList = resOne.data.totalList;
        },
        (errOne) => {}
      );
  },
  methods:{
    chooseThisClassify(event,info){
      viewArtical(info.id).then(res=>{
        if(res.code!=200){
          this.$message.error(res.message)
          return;
        }
        this.$store.commit('changeArticalInRightnowClickClassify',res.data)
        this.$router.push({path:`/showArticalListInfo/${info.classify_name}`})
      },err=>{})
    }
  }
};
</script>
  <style lang="scss" scoped>
.middleContainer {
  width: 50vw;
  .container {
    margin-left: 2vw;
    opacity: 0.8;
    width: 46vw;
    height: auto;
    background-color: #feeeed;
    border-radius: 0.6rem;
    margin-top: 1.2rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
    .classify_bottom {
      height: auto;
      .classOneTier {
        list-style: none;
        padding-left: 1rem;
        margin-left: 1rem;
        margin-top: 1rem;
        font-size: 1.1rem;
        .classOneTierText {
          height: 1.8rem;
          .oneTierShowBox {
            border-radius: 0.3rem;
            display: flex;
            .childrenOnTier:nth-child(1) {
              line-height: 1.8rem;
              padding-left: 0.4rem;
              flex: 1;
            }
            .childrenOnTier:nth-child(2) {
              line-height: 1.8rem;
              flex: 1;
              text-align: right;
              padding-right: 0.4rem;
            }
          }
          .oneTierShowBox:hover {
            background-color: #afb4db;
            cursor: pointer;
          }
        }
      }
      .labelList {
        margin-left: 1rem;
        overflow: hidden;
        .list_item {
          display: inline-block;
          border: 2px solid #afb4db;
          margin: 0.3rem;
          border-radius: 0.2rem;
          a {
            text-decoration: none;
            color: black;
          }
        }
      }
    }
  }
}
</style>